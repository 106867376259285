import React from 'react'

function BedroomHS() {
    return (
        <svg width="49" height="64" viewBox="0 0 49 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.6008 46.7183C12.3166 47.7454 13.0781 48.8347 13.8883 49.9935C16.2097 53.314 18.9297 57.2045 22.1119 61.8363C23.2827 63.5345 25.7872 63.5346 26.9581 61.8363C30.1403 57.2045 32.8603 53.314 35.1817 49.9935C35.9918 48.8347 36.7534 47.7454 37.4691 46.7183C33.6646 48.9201 29.247 50.1804 24.535 50.1804C19.823 50.1804 15.4054 48.9201 11.6008 46.7183Z" fill="white"/>
<rect x="0.696899" y="0.82373" width="47.6761" height="47.6761" rx="23.838" stroke="white"/>
<rect x="3.23914" y="3.36621" width="42.5916" height="42.5916" rx="21.2958" fill="white" fill-opacity="0.9"/>
<path d="M31.887 23.6479C32.5972 23.6479 33.2806 23.9188 33.7978 24.4053C34.3151 24.8919 34.6273 25.5575 34.6707 26.2663L34.6757 26.4366V33.0282C34.6757 33.2209 34.6025 33.4064 34.4709 33.5472C34.3394 33.688 34.1593 33.7736 33.967 33.7868C33.7748 33.7999 33.5847 33.7396 33.4352 33.618C33.2857 33.4963 33.188 33.3225 33.1617 33.1316L33.1546 33.0282V30.7465H15.9152V33.0282C15.9152 33.212 15.8486 33.3896 15.7278 33.5281C15.607 33.6666 15.4401 33.7567 15.258 33.7817L15.1546 33.7888C14.9708 33.7887 14.7932 33.7222 14.6547 33.6014C14.5162 33.4806 14.4261 33.3137 14.4011 33.1316L14.394 33.0282V26.4366C14.394 25.7265 14.6649 25.0431 15.1515 24.5258C15.638 24.0085 16.3036 23.6964 17.0124 23.653L17.1828 23.6479H31.887ZM19.2109 16.5493H29.8588C30.569 16.5493 31.2524 16.8202 31.7697 17.3067C32.2869 17.7933 32.5991 18.4589 32.6425 19.1677L32.6476 19.338V22.6338H29.6053L29.5982 22.5152C29.5715 22.2888 29.4693 22.078 29.3082 21.9169C29.147 21.7557 28.9362 21.6535 28.7099 21.6268L28.5912 21.6197H26.5631C26.3147 21.6198 26.0749 21.711 25.8893 21.876C25.7037 22.0411 25.5851 22.2685 25.5561 22.5152L25.549 22.6338H23.5208L23.5137 22.5152C23.487 22.2888 23.3848 22.078 23.2237 21.9169C23.0625 21.7557 22.8517 21.6535 22.6254 21.6268L22.5067 21.6197H20.4786C20.2302 21.6198 19.9904 21.711 19.8048 21.876C19.6192 22.0411 19.5006 22.2685 19.4716 22.5152L19.4645 22.6338H16.4222V19.338C16.4222 18.6279 16.6931 17.9445 17.1796 17.4272C17.6662 16.9099 18.3318 16.5978 19.0406 16.5544L19.2109 16.5493Z" fill="#850C70"/>
</svg>
    )
}

export default BedroomHS
