import create from 'zustand'
import BathroomHS from './components/assets/Hotspots/BathroomHS'
import BedroomHS from './components/assets/Hotspots/BedroomHS'
import KitchenHS from './components/assets/Hotspots/KitchenHS'
import LivingRoomHS from './components/assets/Hotspots/LivingRoomHS'
import MasterBedroomHS from './components/assets/Hotspots/MasterBedroomHS'

export const useCameraControls = create((set) => ({
  cameraValues: [{ position: [0, 0, 0], lookAt: [0, 0, 0] }],
  setCamValues: (Values) => {
    set((state) => ({
      cameraValues: [{ position: Values.position, lookAt: Values.lookAt }],
    }))
  },
}))

export const useCamRef = create((set) => ({
  controlRef: null,
  cameraRef: null,
  setControlRef: (ref) => {
    set((state) => ({ controlRef: ref }))
  },
  setCameraRef: (ref) => {
    set((state) => ({ cameraRef: ref }))
  },
}))

export const useApartmentPreview = create((set) => ({
  apartmentPreview: false, //Default : false
  lottieState: false,
  setLottieState: (bool) => {
    set((state) => ({ lottieState: bool }))
  },
  setApartmentPreview: (bool) => {
    set((state) => ({ apartmentPreview: bool }))
  },
}))

export const useGuidedTour = create((set) => ({
  guidedTour: false,
  pauseTour: false,
  tourCompleted: false,
  infoPopup: false,
  setInfoPopup: (bool) => {
    set((state) => ({ infoPopup: bool }))
  },
  setGuidedTour: (bool) => {
    set((state) => ({ guidedTour: bool }))
  },
  setPauseTour: (bool) => {
    set((state) => ({ pauseTour: bool }))
  },
  setTourCompleted: (bool) => {
    set((state) => ({ tourCompleted: bool }))
  },
}))

export const useTimeOfDaySwitch = create((set) => ({
  lighting: true,
  setLighting: (bool) => {
    set((state) => ({ lighting: bool }))
  },
}))

export const useMenuButtons = create((set) => ({
  showGuide: false,
  showMap: false,
  setShowGuide: (bool) => {
    set((state) => ({ showGuide: bool }))
  },
  setShowMap: (bool) => {
    set((state) => ({ showMap: bool }))
  },
}))

export const useHotspotState = create((set) => ({
  hotspotVisibility: false, //Default : false
  setHotspotVisibility: (bool) => {
    set((state) => ({ hotspotVisibility: bool }))
  },
}))

export const useTutorial = create((set, get) => ({
  showOnce: true,
  showHint: false,
  setShowHint: (bool) => {
    set((state) => ({ showHint: bool }))
  },
  setShowOnce: (bool) => {
    set((state) => ({ showOnce: bool }))
  },
}))

export const useTutorial1 = create((set, get) => ({
  count: 0,
  setCount: () => {
    if (get().count === 4) {
    } else {
      let temp = get().count + 1
      set((state) => ({ count: temp }))
    }
  },
}))

export const useMaster = create((set, get) => ({
  objects: {
    '/': {
      hotspots: [],
    },
    '/home': {
      hotspots: [
        {
          position: [2, 4, -0.5],
          mobilePosition: [4, 6, -1.5],
          scale: 1,
          title: 'Bathroom',
          target: '/bathroom',
          svg: <BathroomHS />,
          type: 'apartmentPreview',
        },
        {
          position: [-3, 4, 1],
          mobilePosition: [-3, 6, 1.5],
          scale: 1,
          title: 'Toilet',
          target: '/toilet',
          svg: <BathroomHS />,
          type: 'apartmentPreview',
        },
        {
          position: [2, 4, -3],
          mobilePosition: [4, 6, -5],
          scale: 1,
          title: 'MasterBedroom',
          target: '/masterbedroom1',
          svg: <MasterBedroomHS />,
          type: 'apartmentPreview',
        },
        {
          position: [-2, 4, -3],
          mobilePosition: [-3, 6, -5],
          scale: 1,
          title: 'Bedroom',
          target: '/bedroom',
          svg: <BedroomHS />,
          type: 'apartmentPreview',
        },
        {
          position: [2, 4, 1.3],
          mobilePosition: [4, 6, 1.5],
          scale: 1,
          title: 'Kitchen',
          target: '/kitchen',
          svg: <KitchenHS />,
          type: 'apartmentPreview',
        },
        {
          position: [0, 4, 4],
          mobilePosition: [-1, 6, 5],
          scale: 1,
          title: 'LivingRoom',
          target: '/livingroom1',
          svg: <LivingRoomHS />,
          type: 'apartmentPreview',
        },
      ],
    },
    '/bathroom': {
      title: 'Bathroom',
      url: './360renders/Daylight/2BHKAMBathroomDL.webp',
      nightUrl: './360renders/Nightlight/2BHKAMBathroomNL.jpg',
      hotspots: [
        // {
        //   position: [-200, -25, 0],
        //   mobilePosition: [-294.839, -38.566, 5.466],
        //   width: 75,
        //   height: 95,
        //   title: 'Bathroom',
        //   target: '/bathroom',
        //   type: 'Info',
        // },
        {
          position: [208, -284, 30],
          mobilePosition: [262.44, -128.32, -62.295],
          mobileScale: [30, 30, 30],
          scale: [22, 22, 22],
          rotation: [1.6, 0, 1.6],
          title: 'MasterBedroom1',
          target: '/masterbedroom1',
          type: 'FreeRoam',
        },
        {
          position: [311, -156, -69],
          mobilePosition: [178.17, -240.621, 14.09],
          mobileScale: [30, 30, 30],
          scale: [14, 14, 14],
          rotation: [1.6, 0, 1.6],
          title: 'MasterBedroom1',
          target: '/passage2',
          type: 'FreeRoam',
        },
      ],

      camera: { position: [0, 0, 0], lookAt: [110.917, 39.219, 24.915] },
      mapMarkerPosition: {
        right: -16.4,
        bottom: 11,
        mobileX: -24,
        mobileY: 18,
      },
    },
    '/toilet': {
      title: 'Toilet',
      url: './360renders/Daylight/2BHKAToiletDL.webp',
      nightUrl: './360renders/Nightlight/2BHKAToiletNL.jpg',
      hotspots: [
        // {
        //   position: [-200, -19, 0],
        //   mobilePosition: [-296.618, -26.808, 17.368],
        //   width: 75,
        //   height: 95,
        //   title: 'Toilet',
        //   target: '/bathroom',
        //   type: 'Info',
        // },
        {
          position: [173, -211, -7],
          mobilePosition: [195.516, -228.138, -10.961],
          mobileScale: [30, 30, 30],
          scale: [18, 18, 18],
          rotation: [1.6, 0, 1.6],
          title: 'Toilet',
          target: '/passage',
          type: 'FreeRoam',
        },
        {
          position: [325, -122, -6],
          mobilePosition: [304.253, -111.911, -13.202],
          mobileScale: [25, 25, 25],
          scale: [12, 12, 12],
          rotation: [1.6, 0, 1.6],
          title: 'Toilet',
          target: '/kitchen',
          type: 'FreeRoam',
        },
        // {
        //   position: [173, -211, -7],
        //   mobilePosition: [195.516, -228.138, -10.961],
        //   mobileScale: [25, 25, 25],
        //   scale: [18, 18, 18],
        //   rotation: [-1.5, 0, 0],
        //   title: 'Toilet',
        //   target: '/kitchen',
        //   type: 'Arrow',
        // },
      ],

      camera: { position: [0, 0, 0], lookAt: [110.917, 39.219, 24.915] },
      mapMarkerPosition: {
        right: -10.9,
        bottom: 26.8,
        mobileX: -15.9,
        mobileY: 42.8,
      },
    },
    '/masterbedroom2': {
      title: 'Master Bedroom',
      url: './360renders/Daylight/2BHKAMBedroomDL.webp',
      nightUrl: './360renders/Nightlight/2BHKAMBedroomNL.jpg',
      hotspots: [
        // {
        //   position: [-448, 25, 386],
        //   mobilePosition: [-211.698, 14.465, 207.573],
        //   width: 75,
        //   height: 95,
        //   title: 'MasterBedroom',
        //   target: '/masterbedroom',
        //   type: 'View',
        // },
        // {
        //   position: [-360, -32, -121],
        //   mobilePosition: [-288.018, -34.774, -66.169],
        //   width: 75,
        //   height: 95,
        //   title: 'MasterBedroom',
        //   target: '/masterbedroom',
        //   type: 'Info',
        // },
        {
          position: [69.753, -243.813, -147.072],
          mobilePosition: [69.753, -243.813, -147.072],
          mobileScale: [30, 30, 30],
          scale: [20.0, 20.0, 20.0],
          rotation: [1.6, 0, 0.6],
          title: 'MasterBedroom',
          target: '/masterbedroom1',
          type: 'FreeRoam',
        },
      ],
      camera: { position: [0, 0, 0], lookAt: [12.705, 1.331, -3.517] },
      mapMarkerPosition: {
        right: -19,
        bottom: 15,
        mobileX: -28,
        mobileY: 24,
      },
    },
    '/masterbedroom1': {
      title: 'Master Bedroom',
      url: './360renders/Daylight/2BHKAMBedroomAntespaceDL.webp',
      nightUrl: './360renders/Nightlight/2BHKAMBedroomAntespaceNL.jpg',
      hotspots: [
        {
          position: [-332, -218, -94],
          mobilePosition: [-227.969, -166.677, -88.326],
          mobileScale: [30, 30, 30],
          scale: [18, 18, 18],
          rotation: [1.6, 0, 1.6],
          title: 'MasterBedroom1',
          target: '/masterbedroom2',
          type: 'FreeRoam',
        },
        // {
        //   position: [-176, -218, -27],
        //   mobilePosition: [-185.276, -227.689, -26.073],
        //   mobileScale: [30, 30, 30],
        //   scale: [15, 15, 15],
        //   rotation: [1.6, 0, 1.6],
        //   title: 'MasterBedroom1',
        //   target: '/masterbedroom2',
        //   type: 'Arrow',
        // },
        {
          position: [-35.103, -232.999, -178.905],
          mobilePosition: [-35.103, -232.999, -178.905],
          mobileScale: [30, 30, 30],
          scale: [18, 18, 18],
          rotation: [1.6, 0, 0],
          title: 'MasterBedroom1',
          target: '/bathroom',
          type: 'FreeRoam',
        },
        {
          position: [211, -356, 554],
          mobilePosition: [100.119, -154.409, 232.348],
          mobileScale: [30, 30, 30],
          scale: [24, 24, 24],
          rotation: [1.6, 0, 0],
          title: 'MasterBedroom1',
          target: '/passage2',
          type: 'FreeRoam',
        },
        // {
        //   position: [70, -140, 100],
        //   mobilePosition: [114.642, -221.339, 158.438],
        //   mobileScale: [30, 30, 30],
        //   scale: [7.5, 7.5, 7.5],
        //   rotation: [1.6, 0, 2.4],
        //   title: 'MasterBedroom1',
        //   target: '/masterbedroom2',
        //   type: 'Arrow',
        // },
      ],
      camera: { position: [0, 0, 0], lookAt: [12.323, 0.094, 4.867] },
      mapMarkerPosition: {
        right: -16.5,
        bottom: 14.8,
        mobileX: -25,
        mobileY: 24,
      },
    },
    '/bedroom': {
      title: 'Bedroom',
      url: './360renders/Daylight/2BHKABedroom2DL.webp',
      nightUrl: './360renders/Nightlight/2BHKABedroom2NL.jpg',
      hotspots: [
        // {
        //   position: [-94, 12, -160],
        //   mobilePosition: [-150.684, 32.926, -255.158],
        //   width: 65,
        //   height: 85,
        //   title: 'Bathroom',
        //   target: '/bedroom',
        //   type: 'View',
        // },
        {
          position: [125, -126, 340],
          mobilePosition: [98.129, -97.473, 263.284],
          mobileScale: [30, 30, 30],
          scale: [12, 12, 12],
          rotation: [1.6, 0, -0.2],
          title: 'Bathroom',
          target: '/passage',
          type: 'FreeRoam',
        },
        {
          position: [80, -126, 150],
          mobilePosition: [106.058, -184.728, 202.939],
          mobileScale: [30, 30, 30],
          scale: [10, 10, 10],
          rotation: [1.6, 0, -0.2],
          title: 'Bathroom',
          target: '/passage2',
          type: 'FreeRoam',
        },
        // {
        //   position: [74, -126, 125],
        //   mobilePosition: [106.058, -184.728, 202.939],
        //   mobileScale: [30, 30, 30],
        //   scale: [12.0, 12.0, 12.0],
        //   rotation: [1.6, 0, -0.2],
        //   title: 'Bathroom',
        //   target: '/passage2',
        //   type: 'Arrow',
        // },
        // {
        //   position: [-527, -108, -160],
        //   mobilePosition: [-276.61, -65.736, -81.219],
        //   width: 75,
        //   height: 95,
        //   title: 'Bathroom',
        //   target: '/bedroom',
        //   type: 'Info',
        // },
      ],
      camera: { position: [0, 0, 0], lookAt: [11.276, 2.224, 6.594] },
      mapMarkerPosition: {
        right: -19.8,
        bottom: 23,
        mobileX: -29.8,
        mobileY: 37,
      },
    },
    '/passage2': {
      title: 'Passage',
      url: './360renders/Daylight/2BHKAPassage2DL.webp',
      nightUrl: './360renders/Nightlight/2BHKAPassage2NL.jpg',
      hotspots: [
        {
          position: [-343, -234, 104],
          mobilePosition: [-234.496, -158.814, 71.983],
          mobileScale: [30, 30, 30],
          scale: [18, 18, 18],
          rotation: [1.6, 0, 1.6],
          title: 'Passage2',
          target: '/bedroom',
          type: 'FreeRoam',
        },
        // {
        //   position: [-261, -234, 53],
        //   mobilePosition: [-210.501, -198.411, 55.494],
        //   mobileScale: [30, 30, 30],
        //   scale: [18, 18, 18],
        //   rotation: [1.6, 0, 1.2],
        //   title: 'Passage2',
        //   target: '/bedroom',
        //   type: 'Arrow',
        // },
        {
          position: [-139, -234, -372],
          mobilePosition: [-90.52, -151.746, -239.475],
          mobileScale: [30, 30, 30],
          scale: [20, 20, 20],
          rotation: [1.6, 0, 0],
          title: 'Passage2',
          target: '/masterbedroom1',
          type: 'FreeRoam',
        },
        {
          position: [-139, -234, -567],
          mobilePosition: [-67.643, -113.43, -263.699],
          mobileScale: [30, 30, 30],
          scale: [20, 20, 20],
          rotation: [1.6, 0, 0],
          title: 'Passage2',
          target: '/bathroom',
          type: 'FreeRoam',
        },
        {
          position: [374, -234, 3],
          mobilePosition: [256.639, -144.217, 3.296],
          mobileScale: [30, 30, 30],
          scale: [22, 22, 22],
          rotation: [1.6, 0, 1.6],
          title: 'Passage2',
          target: '/passage',
          type: 'FreeRoam',
        },
        // {
        //   position: [-39, -234, -234],
        //   mobilePosition: [-33.319, -199.927, -215.596],
        //   mobileScale: [30, 30, 30],
        //   scale: [18, 18, 18],
        //   rotation: [1.6, 0, 2.2],
        //   title: 'Passage2',
        //   target: '/masterbedroom1',
        //   type: 'Arrow',
        // },
      ],
      camera: { position: [0, 0, 0], lookAt: [12.323, 0.094, 4.867] },
      mapMarkerPosition: {
        right: 128,
        bottom: 60,
        mobileX: -22.3,
        mobileY: 34.5,
      },
    },
    '/kitchen': {
      title: 'Kitchen',
      url: './360renders/Daylight/2BHKAKitchenDL.webp',
      nightUrl: './360renders/Nightlight/2BHKAKitchenNL.jpg',
      hotspots: [
        {
          position: [182.701, -229.212, -15.646],
          mobilePosition: [182.701, -229.212, -15.646],
          mobileScale: [30, 30, 30],
          scale: [15, 15, 15],
          rotation: [1.6, 0, 1.6],
          title: 'Kitchen',
          target: '/passage',
          type: 'FreeRoam',
        },
        {
          position: [244.194, -158.259, -15.646],
          mobilePosition: [244.194, -158.259, -15.646],
          mobileScale: [30, 30, 30],
          scale: [12, 12, 12],
          rotation: [1.6, 0, 1.6],
          title: 'Kitchen',
          target: '/toilet',
          type: 'FreeRoam',
        },
        // {
        //   position: [-102, -49, 161],
        //   mobilePosition: [-148.339, -92.516, 235.732],
        //   width: 100,
        //   height: 130,
        //   title: 'Kitchen',
        //   target: '/kitchen',
        //   type: 'Info',
        // },
        // {
        //   position: [-211, -130, 11],
        //   mobilePosition: [-262.617, -135.194, 14.686],
        //   mobileScale: [30, 30, 30],
        //   scale: [10.0, 10.0, 10.0],
        //   rotation: [1.6, 0, 1.6],
        //   title: 'Kitchen',
        //   target: '/passage',
        //   type: 'Arrow',
        // },
      ],
      camera: { position: [0, 0, 0], lookAt: [13.109, 1.555, 1.144] },
      mapMarkerPosition: {
        right: -11.2,
        bottom: 12,
        mobileX: -16.5,
        mobileY: 20,
      },
    },
    '/livingroom1': {
      title: 'Living Room',
      url: './360renders/Daylight/2BHKALivRoom1DL.webp',
      nightUrl: './360renders/Nightlight/2BHKALivRoom1NL.jpg',
      hotspots: [
        {
          position: [-343, -92, -93],
          mobilePosition: [-277.701, -75.235, -72.196],
          mobileScale: [30, 30, 30],
          scale: [10.0, 10.0, 10.0],
          rotation: [1.6, 0, 2],
          title: 'LivingRoom1',
          target: '/livingroom2',
          type: 'FreeRoam',
        },
        // {
        //   position: [-110, -5, 100],
        //   mobilePosition: [-228.499, -32.128, 189.492],
        //   width: 75,
        //   height: 95,
        //   title: 'LivingRoom1',
        //   target: '/livingroom',
        //   type: 'Info',
        // },
        // {
        //   position: [-173, -74, -39],
        //   mobilePosition: [-264.453, -113.516, -58.137],
        //   mobileScale: [30, 30, 30],
        //   scale: [7.5, 7.5, 7.5],
        //   rotation: [1.6, 0, 2.2],
        //   title: 'LivingRoom1',
        //   target: '/livingroom2',
        //   type: 'Arrow',
        // },
      ],
      camera: { position: [0, 0, 0], lookAt: [13.098, 1.219, 1.593] },
      mapMarkerPosition: {
        right: -1.5,
        bottom: 35,
        mobileX: -2.5,
        mobileY: 56,
      },
    },
    '/livingroom2': {
      title: 'Living Room',
      url: './360renders/Daylight/2BHKALivRoom2DL.webp',
      nightUrl: './360renders/Nightlight/2BHKALivRoom2NL.jpg',
      hotspots: [
        {
          position: [341, -101, 101],
          mobilePosition: [269.998, -84.684, 74.566],
          mobileScale: [30, 30, 30],
          scale: [10, 10, 10],
          rotation: [1.6, 0, 2],
          title: 'LivingRoom2',
          target: '/livingroom1',
          type: 'FreeRoam',
        },
        // {
        //   position: [157, -101, 34],
        //   mobilePosition: [242.46, -156.466, 40.545],
        //   mobileScale: [30, 30, 30],
        //   scale: [7.5, 7.5, 7.5],
        //   rotation: [1.6, 0, 1.95],
        //   title: 'LivingRoom2',
        //   target: '/livingroom1',
        //   type: 'Arrow',
        // },
        {
          position: [-20, -89, 260],
          mobilePosition: [-11.757, -95.048, 278.919],
          mobileScale: [30, 30, 30],
          scale: [10, 10, 10],
          rotation: [1.6, 0, 0.4],
          title: 'LivingRoom2',
          target: '/passage',
          type: 'FreeRoam',
        },
        // {
        //   position: [14, -89, 178],
        //   mobilePosition: [11.864, -124.525, 265.921],
        //   mobileScale: [30, 30, 30],
        //   scale: [10.0, 10.0, 10.0],
        //   rotation: [1.6, 0, 0.6],
        //   title: 'LivingRoom2',
        //   target: '/passage',
        //   type: 'Arrow',
        // },
        // {
        //   position: [221, 13, 208],
        //   mobilePosition: [213.943, -4.363, 205.312],
        //   width: 65,
        //   height: 85,
        //   title: 'Bathroom',
        //   target: '/livingroom',
        //   type: 'Info',
        // },
        // {
        //   position: [-283.186, 9.337, -75.725],
        //   mobilePosition: [-283.186, 9.337, -75.725],
        //   width: 65,
        //   height: 85,
        //   title: 'Bathroom',
        //   target: '/livingroom',
        //   type: 'View',
        // },
      ],
      camera: { position: [0, 0, 0], lookAt: [1.97, 2.887, -12.781] },
      mapMarkerPosition: {
        right: -3.2,
        bottom: 23,
        mobileX: -4.5,
        mobileY: 38,
      },
    },
    '/passage': {
      title: 'Passage',
      url: './360renders/Daylight/2BHKAPassage1DL.webp',
      nightUrl: './360renders/Nightlight/2BHKAPassage1NL.jpg',
      hotspots: [
        {
          position: [-25, -95, -201],
          mobilePosition: [-38.045, -129.945, -261.911],
          mobileScale: [30, 30, 30],
          scale: [10, 10, 10],
          rotation: [1.6, 0, 0],
          title: 'Passage',
          target: '/kitchen',
          type: 'FreeRoam',
        },
        // {
        //   position: [-27, -95, -139],
        //   mobilePosition: [-56.782, -169.703, -229.191],
        //   mobileScale: [30, 30, 30],
        //   scale: [10, 10, 10],
        //   rotation: [1.6, 0, 3.2],
        //   title: 'Passage',
        //   target: '/kitchen',
        //   type: 'Arrow',
        // },
        // {
        //   position: [-126, -95, 1],
        //   mobilePosition: [-232.033, -170.697, 4.003],
        //   mobileScale: [30, 30, 30],
        //   scale: [10, 10, 10],
        //   rotation: [1.6, 0, 1.6],
        //   title: 'Passage',
        //   target: '/passage2',
        //   type: 'Arrow',
        // },
        {
          position: [383, -152, -153],
          mobilePosition: [252.584, -109.238, -100.396],
          mobileScale: [30, 30, 30],
          scale: [12, 12, 12],
          rotation: [1.6, 0, 1.6],
          title: 'Passage',
          target: '/livingroom2',
          type: 'FreeRoam',
        },
        {
          position: [-719, -234, 81],
          mobilePosition: [-277.9, -90.512, 27.589],
          mobileScale: [30, 30, 30],
          scale: [20, 20, 20],
          rotation: [1.6, 0, 1.6],
          title: 'Passage',
          target: '/bedroom',
          type: 'FreeRoam',
        },
        {
          position: [-51, -234, 212],
          mobilePosition: [-40.117, -222.4, 184.613],
          mobileScale: [30, 30, 30],
          scale: [18, 18, 18],
          rotation: [1.6, 0, 0],
          title: 'Bathroom',
          target: '/toilet',
          type: 'FreeRoam',
        },
        {
          position: [-431, -234, 4],
          mobilePosition: [-260.97, -138.375, 1.362],
          mobileScale: [30, 30, 30],
          scale: [20, 20, 20],
          rotation: [1.6, 0, 1.6],
          title: 'Bathroom',
          target: '/passage2',
          type: 'FreeRoam',
        },
      ],
      camera: { position: [0, 0, 0], lookAt: [11.961, 2.335, 5.2] },
      mapMarkerPosition: {
        right: -10.2,
        bottom: 21.8,
        mobileX: -15.2,
        mobileY: 35,
      },
    },
  },

  getObject: (location) => {
    let targ = get().objects[location]
    if (targ != null) {
      return targ
    } else return null
  },
}))

export const useBlur = create((set) => ({
  blur: false,
  setBlur: (newState) => set((state) => (state.blur = newState)),
}))

export const usePanoramaLoading = create((set) => ({
  isLoading: false,
  setLoading: (loading) => set((state) => (state.loading = loading)),
}))

export const useEverythingLoaded = create((set) => ({
  everythingLoaded: false, //Default:false
  agreementAccepted: false, //Default:false
  setAgreementAccepted: (bool) => set((state) => ({ agreementAccepted: bool })),
  setEverythingLoaded: (bool) => set((state) => ({ everythingLoaded: true })),
}))

const flattenLocations = (loc) => {
  // Flatten and add era field to location objects
  let flattenedArray = []
  for (let idx in loc) {
    if (loc[idx].url != null) {
      flattenedArray = [...flattenedArray, loc[idx].url, loc[idx].nightUrl]
    }
  }
  // flattenedArray = flattenedArray.map((x,idx) => ({...x,locationId:idx}))
  return flattenedArray
}

export const flattenedLocations = flattenLocations(useMaster.getState().objects)

export const useLoader = create((set) => ({
  loading: false,
  items: flattenedLocations,
  loadedModel: null,
  setLoadedModel: (model) => set((state) => ({ loadedModel: model })),
  reportDone: (name) =>
    set((state) => {
      return { ...state, items: state.items.filter((x) => x !== name) }
    }),

  // items:state.items.filter(x => x.name !== name)
  // return {...state, items:state.items.filter(x => x.name !== name)}

  addItems: (newItems) =>
    set((state) => (state.items = [...state.items, ...newItems])),
}))

export const usePanoramaViewer = create((set) => ({
  lastLocation: {},
  allLocations: flattenedLocations,
  currentLocation: flattenedLocations[flattenedLocations.length - 1],
  setCurrentLocation: (loc) =>
    set((state) => {
      // if(!state.currentLocation.loading)
      state.lastLocation = state.currentLocation
      state.currentLocation = loc
    }),
  // setDisplay : (flag) => set((state)=>state.displayLocation=flag),
  setLastLocation: (loc) =>
    set((state) => {
      state.lastLocation = loc
    }),
}))
