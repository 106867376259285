import React, { Suspense, useState } from 'react'
import { useTexture, PerspectiveCamera } from '@react-three/drei'
import { useEffect, useRef } from 'react'
import * as THREE from 'three'
// import { LoadingSc } from '../Loading';
import { useThree } from '@react-three/fiber'
// import { HotspotManager } from './HotspotManager';
import { useFrame } from '@react-three/fiber'
import { useSpring } from '@react-spring/core'
import { animated as a } from '@react-spring/three'
// import { Blur } from './Blur';
import {
  useMaster,
  usePanoramaViewer,
  useBlur,
  usePage,
  usePanoramaLoading,
  usePreloader,
  useSky,
  useLoader,
  useEverythingLoaded,
  useCameraControls,
  useTimeOfDaySwitch,
  useHotspotState,
} from '../../store'
import { springConfigs } from '../../constants'
import HotspotManager from './HotspotManager'
import { useLocation } from 'wouter'
import LastLocations from '../context/LastLocations'

export const Panorama = (props) => {
  // const {scene} = useThree()
  // const skyboxState = useSky()
  const url = props.currentLocation
  // const textures = usePreloader()
  // const pageState = usePage()
  // console.log(`textures.textures`, textures.textures)
  // const skybox = textures.textures[Math.round(Math.random()*10)]
  let fadedInOnce = useRef(false)
  const skybox = useTexture(url)
  const mesh = useRef()
  const group = useRef()
  const { reportDone } = useLoader()
  const panoramaLoadingState = usePanoramaLoading()
  const { camera, gl, scene } = useThree()
  // const blur = useBlur()
  // const locationsState = useLocations()
  // function initialize(){
  //     mesh.current.geometry.scale(-1,1,1);
  //     skybox.encoding = THREE.sRGBEncoding;
  //     let mat = new THREE.MeshBasicMaterial({map:skybox});
  //     mesh.current.material = mat;
  //     mesh.current.material.transparent = true;
  //     mesh.current.material.opacity = 1;
  // }
  const canvas = gl.domElement
  const [showHotspots, setShowHotspots] = useState(true)

  useEffect(() => {
    const unsub = useHotspotState.subscribe(
      (paw, previousPaw) => {
        setShowHotspots(paw)
      },
      (state) => state.hotspotVisibility
    )

    return function cleanup() {
      unsub()
    }
  }, [])

  useEffect(() => {
    // skybox.encoding = THREE.sRGBEncoding
    mesh.current.geometry.scale(-1, 1, 1)
  }, [])

  useEffect(() => {
    reportDone(props.currentLocation)
    fadedInOnce.current = true
  }, [props.currentLocation])

  useFrame((state) => {
    // console.log(`state.mouse`, Object.values(camera.getWorldDirection()).map(x=>Number((x*100).toFixed(0))))
    // console.log(`state.mouse`, Object.values(camera.getWorldDirection()).map(x=>Number((x).toFixed(4))))
    // raycaster.setFromCamera(camera,state.mouse)
  })
  const fadeOut = useSpring({
    from: { opacity: 1 },
    config: springConfigs.precision,
    opacity: 0,
    reset: true,
    onRest: () => props.exit && props.setLastLocationToCurrent(),
  })
  const fadeIn = useSpring({
    from: { opacity: 0 },
    config: springConfigs.precision,
    opacity: 1,
    reset: true,
  })
  return (
    <group ref={group}>
      {/* {(blur.blur || pageState.page==='introduction') && <Blur />} */}
      {/* <Blur /> */}
      {/* <PerspectiveCamera makeDefault /> */}
      <a.mesh rotation={[0, 0, 0]} ref={mesh}>
        <a.sphereBufferGeometry attach="geometry" args={[300, 60, 40]} />
        <a.meshBasicMaterial
          transparent
          opacity={
            props.exit
              ? fadeOut.opacity.to((x) => x)
              : props.shouldAnimate &&
                props.currentLocation !== props.lastLocation
              ? fadeIn.opacity.to((x) => x)
              : 1
          }
          map={skybox}
        />
      </a.mesh>
      {!props.exit && (
        <HotspotManager
          visibility={
            useEverythingLoaded.getState().agreementAccepted && showHotspots
          }
        />
      )}
    </group>
  )
}

function PanoramaViewer(props) {
  const { everythingLoaded } = useEverythingLoaded()
  const { getObject } = useMaster()
  const [currentObject, setObject] = useState({ url: null })
  const locationsState = usePanoramaViewer()
  const [location] = useLocation()
  const setLastLocationToCurrent = () => {
    locationsState.setLastLocation(locationsState.currentLocation)
  }
  const loader = useLoader()
  const { setCamValues } = useCameraControls()
  const { lighting } = useTimeOfDaySwitch()
  const [state, setState] = useState({
    previous: null,
    next: null,
  })

  useEffect(() => {
    setState({ ...state, next: location })
  }, [])

  useEffect(() => {
    if (state.next !== null) {
      setState({
        ...state,
        previous: state.next,
        next: location,
      })
    }
    if (
      everythingLoaded &&
      props.argument != '/' &&
      props.argument != '/home'
    ) {
      if (lighting) {
        locationsState.setCurrentLocation(getObject(props.argument).url)
      } else {
        locationsState.setCurrentLocation(getObject(props.argument).nightUrl)
      }
    }
  }, [lighting, props.argument])

  useEffect(() => {
    if (
      everythingLoaded &&
      props.argument != '/' &&
      props.argument != '/home'
    ) {
      setObject(getObject(props.argument))
      setCamValues({
        position: getObject(props.argument).camera.position,
        lookAt: getObject(props.argument).camera.lookAt,
      })
    }
  }, [props.argument])

  if (props.argument != '/' && props.argument != '/home') {
    return (
      <group>
        <Suspense fallback={null}>
          {locationsState.lastLocation &&
            locationsState.lastLocation !== locationsState.currentLocation &&
            loader.items.length === 0 &&
            state.previous != '/home' && (
              <>
                <Panorama
                  setLastLocationToCurrent={setLastLocationToCurrent}
                  {...props}
                  exit
                  currentLocation={locationsState.lastLocation}
                />
              </>
            )}
          <Panorama
            shouldAnimate={locationsState.lastLocation}
            exit={false}
            currentLocation={locationsState.currentLocation}
            lastLocation={locationsState.lastLocation}
          />
        </Suspense>
      </group>
    )
  } else return null
}

export default PanoramaViewer
