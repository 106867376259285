import React, {useState, useEffect, useRef} from 'react'
import { isMobile } from 'react-device-detect';
import { Link, useLocation } from 'wouter';
import { useEverythingLoaded, useMaster, usePanoramaViewer } from '../../store';
import Hotspot from './Hotspot';
import HotspotSpawn from './HotspotSpawn';

export default function HotspotManager(props) {

    
    const [location] = useLocation();
    const {getObject} = useMaster()
    const [currentObject,setObject] = useState({hotspots:[]});
    const [hidden, setHidden] = useState(false)

    useEffect(() => {
        setHidden(false)
        setObject(getObject(location));
        setTimeout(() => {
            setHidden(true)
        }, 1500);
    },[location])

    useEffect(() => {
        console.log(props.visibility)
    },[props.visibility])
    


    if (currentObject.hotspots != null ){
        return (
            <>
                {
                    currentObject.hotspots.map((hotspot, index) => 
                    {
                        return(
                            <Hotspot key={index} type={hotspot.type} hidden={(hidden && props.visibility)} position={!isMobile ? hotspot.position : (hotspot.mobilePosition != null ? hotspot.mobilePosition : hotspot.position)} scale={!isMobile ? hotspot.scale : (hotspot.mobileScale != null ? hotspot.mobileScale : hotspot.scale)} rotation={hotspot.rotation} >
                                <Link href={hotspot.target} style={{pointerEvents:'auto'}}>
                                    <HotspotSpawn svg={hotspot.svg != null ? hotspot.svg : null} object={hotspot} visibility={props.visibility}/>
                                </Link>
                            </Hotspot>
                        )
                    })
                }
            </>
        )
    }
    else return null;
  
}

