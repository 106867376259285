import { useEffect, useState } from "react";
import { usePanoramaViewer, useLoader, flattenedLocations, useEverythingLoaded } from "../../store";
import { print } from "../helpers/ConsoleStatements";

export const PanoramaPreLoader = () => {
  const loader = useLoader();
  const [currentLoading, setCurrentLoading] = useState();
  const {setCurrentLocation} = usePanoramaViewer()
  // const {everythingLoaded, setEverythingLoaded} = useEverythingLoaded()

  useEffect(() => {
    if (loader.items.length !== 0) {
      setCurrentLocation(loader.items[loader.items.length - 1]);
      setCurrentLoading(loader.items[loader.items.length - 1]);
    }
    else {
      // if(!everythingLoaded){
      //   // setEverythingLoaded(true)
      //   // console.log("everythingloaded")
      // }
    }
  }, [loader.items]);
  
  return null;
};
