import React from 'react'

function Arrow() {
    return (
        <svg width="134" height="77" viewBox="0 0 134 77" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M114.6 76.4L66.8499 28.65L19.0999 76.4L-6.39573e-05 66.85L66.8499 -2.92211e-06L133.7 66.85L114.6 76.4Z" fill="white"/>
        </svg>
    )
}

export default Arrow
