import { Html } from '@react-three/drei'
import { css, StyleSheet } from 'aphrodite'
import React, { useState, useEffect } from 'react'
import Lottie from 'react-lottie'
import animationData from '../assets/lottie/RotAnimLottie2.json'
import { useTutorial } from '../../store'
import UserPromptContainer from '../assets/images/UserPromptContainer'
import UserPromptContainerFlipped from '../assets/images/UserPromptContainerFlipped'

function UserPrompts3D() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const { showOnce, setShowOnce, setShowHint } = useTutorial()
  const [hintState, setHintState] = useState(false)

  function Timer() {
    setTimeout(() => {
      setHintState(false)
      setShowHint(false)
    }, 5000)
  }

  useEffect(() => {
    // if (showOnce) {
    setHintState(true)
    setShowOnce(false)
    Timer()
    // }
  }, [])

  useEffect(() => {
    const unsub = useTutorial.subscribe(
      (paw, previousPaw) => {
        if (!hintState) {
          setHintState(true)
          Timer()
        }
      },
      (state) => state.showHint
    )

    return function cleaup() {
      unsub()
    }
  }, [])

  if (hintState) {
    return (
      <>
        <group position={[0, -5.5, 2]}>
          <Html>
            <div className={css(styles.dragContainer)}>
              <div className={css(styles.lottieSize)}>
                <Lottie options={defaultOptions} />
              </div>
            </div>
          </Html>
        </group>
        {/* 5,6.5,5 */}
        <group position={[5, 6.5, 5]}>
          <Html sprite center>
            <div className={css(styles.userPromptContainer)}>
              <div className={css(styles.userPromptContainer3)} />
              {/* <UserPromptContainer /> */}

              <div className={css(styles.TextContainer1)}>
                <div className={css(styles.dragText)}>
                  Click and drag the screen to rotate the model
                </div>
              </div>
            </div>
          </Html>
        </group>

        <group position={[-5, 6.5, -5]}>
          <Html sprite center>
            <div className={css(styles.userPromptContainer)}>
              <div className={css(styles.userPromptContainer3)} />
              {/* <UserPromptContainer /> */}
              <div className={css(styles.TextContainer)}>
                <div className={css(styles.dragText)}>
                  Click the button to teleport into this room.
                </div>
              </div>
            </div>
          </Html>
        </group>

        {/* <group position={[0,-7,-6]}>
                <Html>
                    <div className={css(styles.userPromptContainer)}>
                        <UserPromptContainer/>
                        <div className={css(styles.TextContainer1)}>
                            <div className={css(styles.dragText)}>Click to begin an automated walkthrough of the apartment. </div>
                        </div>
                    </div>
                </Html>
            </group> */}
      </>
    )
  } else {
    return null
  }
}

export default UserPrompts3D

const styles = StyleSheet.create({
  dragContainer: {
    '@media(min-width:556px) and (orientation:portrait)': {
      display: 'none',
    },
  },
  lottieSize: {
    width: 200,
    height: 100,
    '@media (min-width: 556.98px) and (orientation: landscape)': {
      transform: 'scale(0.6)',
    },
    '@media (min-width: 1200px)': {
      transform: 'scale(1.5)',
    },
    '@media (min-width: 1400px)': {
      transform: 'scale(1)',
    },
    '@media (min-width: 1920px)': {
      transform: 'scale(1.5)',
    },
    '@media (min-width: 2560px)': {
      transform: 'scale(1.8)',
    },
  },
  userPromptContainer: {
    width: 250,
    height: 'auto',
    textAlign: 'center',
    '@media(min-width:556px) and (orientation:portrait)': {
      display: 'none',
    },
    '@media (min-width: 556.98px) and (orientation: landscape)': {
      transform: 'scale(0.6)',
    },
    '@media (min-width: 992px)': {
      transform: 'scale(1)',
    },
    '@media (min-width: 1200px)': {
      transform: 'scale(1.5)',
    },
    '@media (min-width: 1400px)': {
      transform: 'scale(1)',
    },
    '@media (min-width: 1600px)': {
      transform: 'scale(1.2)',
    },
    '@media (min-width: 1920px)': {
      transform: 'scale(1.5)',
    },
    '@media (min-width: 2560px)': {
      transform: 'scale(1.8)',
    },
  },
  userPromptContainer3: {
    backgroundColor: '#ffffffE6',
    borderRadius: '8px',
    textAlign: 'center',
    width: 242,
    height: 82,
  },
  TextContainer: {
    textAlign: 'center',
    position: 'absolute',
    top: 20,
    left: 30,
    width: '80%',
  },
  TextContainer1: {
    textAlign: 'center',
    position: 'absolute',
    top: 10,
    left: 30,
    width: '80%',
    '@media (min-width: 1600px)': {
      top: 20,
    },
    '@media (min-width: 2560px)': {
      top: 20,
    },
  },
  dragText: {
    fontFamily: 'DIN-Regular',
    color: '#850C70',
    fontSize: 18,
    lineHeight: '21px',
    textAlign: 'left',
  },
})
