import React from 'react'
import { useEffect } from 'react/cjs/react.development'
import { usePanoramaViewer } from '../../store'

function LastLocations(props) {

    const {setLastLoc} = usePanoramaViewer()

    useEffect(() => {
        setLastLoc(props.current)
    },[props])
    
    return null;
}

export default LastLocations
