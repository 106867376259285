import React from 'react'

function KitchenHS() {
    return (
        <svg width="49" height="64" viewBox="0 0 49 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7278 46.8735C12.4435 47.9007 13.2051 48.99 14.0152 50.1487C16.3367 53.4692 19.0567 57.3598 22.2389 61.9916C23.4096 63.6898 25.9141 63.6899 27.085 61.9916C30.2672 57.3598 32.9872 53.4692 35.3087 50.1488C36.1188 48.99 36.8804 47.9007 37.5961 46.8735C33.7915 49.0754 29.3739 50.3357 24.6619 50.3357C19.95 50.3357 15.5324 49.0754 11.7278 46.8735Z" fill="white"/>
        <rect x="0.823975" y="0.979004" width="47.6761" height="47.6761" rx="23.838" stroke="white"/>
        <rect x="3.36621" y="3.52148" width="42.5916" height="42.5916" rx="21.2958" fill="white" fill-opacity="0.9"/>
        <path d="M18.983 19.7467C18.6382 20.3044 18.5774 20.7304 18.5774 21.1461C18.5774 21.927 19.5915 23.803 19.5915 24.8171C19.5915 25.7805 19.1858 26.3382 19.1858 26.3382H17.6647C17.6647 26.3382 18.0703 25.7805 18.0703 24.8171C18.0703 23.803 17.0563 21.927 17.0563 21.1461C17.0563 20.7304 17.1171 20.3044 17.4619 19.7467H18.983ZM24.0534 19.7467C23.7087 20.3044 23.6478 20.7304 23.6478 21.1461C23.6478 21.927 24.6619 23.803 24.6619 24.8171C24.6619 25.7805 24.2563 26.3382 24.2563 26.3382H25.7774C25.7774 26.3382 26.183 25.7805 26.183 24.8171C26.183 23.803 25.1689 21.927 25.1689 21.1461C25.1689 20.7304 25.2298 20.3044 25.5746 19.7467H24.0534ZM20.7577 19.7467C20.4129 20.3044 20.352 20.7304 20.352 21.1461C20.352 21.927 21.3661 23.8132 21.3661 24.8171C21.3661 25.7805 20.9605 26.3382 20.9605 26.3382H22.4816C22.4816 26.3382 22.8873 25.7805 22.8873 24.8171C22.8873 23.803 21.8732 21.927 21.8732 21.1461C21.8732 20.7304 21.934 20.3044 22.2788 19.7467H20.7577ZM34.2653 19.2397C34.2653 19.2397 34.3971 18.1647 34.3971 17.7185C34.3971 16.0453 33.0281 14.6763 31.3549 14.6763C29.7932 14.6763 28.5053 15.8526 28.3329 17.3636L27.2275 27.8594H16.5594C15.9509 27.8594 15.454 28.3968 15.5453 29.0053C16.0726 32.3822 18.9729 34.958 22.3802 34.958C25.9092 34.958 28.8095 32.1895 29.1847 28.6402L30.3509 17.6171C30.4016 17.0999 30.8275 16.7044 31.3549 16.7044C31.9126 16.7044 32.3689 17.1608 32.3689 17.7185C32.3689 18.0227 32.2675 18.9861 32.2675 18.9861L34.2653 19.2397Z" fill="#850C70"/>
        </svg>
    )
}

export default KitchenHS
