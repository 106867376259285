export const LoadingScreenText = {
  agreementtext:
    'The experience is an Artist’s Impression. The Project “Birla Tisya“ comprising of 2 Towers and a Club house is registered with Karnataka RERA under the Registration No. PRM/KA/RERA/1251/309/PR/211022/004371 and can be viewed at: ',
  accept: 'ACCEPT AND CONTINUE',
  splashScreenText: 'WELCOME TO THE WALKTHROUGH OF',
  splashScreenTisya: 'Birla Tisya Rajajinagar',
}

export const ApartmentInfoText = {
  type: '2 BHK - A',
  tower: 'T1 - 01',
  tour: 'START THE TOUR',
  // GUIDED
}

export const springConfigs = {
  linear: {
    mass: 15.6,
    tension: 120,
    friction: 3,
    clamp: true,
    velocity: 1,
  },
  heavyLinear: {
    mass: 18.1,
    tension: 462,
    friction: 159,
    clamp: true,
    velocity: 0.1,
  },
  precision: {
    mass: 110,
    tension: 500,
    friction: 53,
    clamp: true,
  },
}

export const Clamps = {
  active: {
    minAngle: Math.PI / 4,
    maxAngle: Math.PI / 2,
    minAzimuth: -(Math.PI / 5),
    maxAzimuth: Math.PI / 3.5,
    minDistance: 25,
    maxDistance: 36,
  },
  inActive: {
    minAngle: -Math.PI / 2,
    maxAngle: Math.PI / 2,
    minAzimuth: Infinity,
    maxAzimuth: Infinity,
    minDistance: 0,
    maxDistance: 1000,
  },
  inTransition: {
    minAngle: Math.PI / 4,
    maxAngle: Math.PI / 2,
    minAzimuth: -(Math.PI / 4),
    maxAzimuth: Math.PI / 5,
    minDistance: 25,
    maxDistance: 36,
  },
}

export const loadingItems = ['PreviewModel']
export const panoramas = [
  '/livingroom1',
  '/livingroom2',
  '/passage',
  '/kitchen',
  '/toilet',
  '/bedroom',
  '/masterbedroom1',
  '/bathroom',
  '/masterbedroom2',
]
//Living room 1 - Living room 2 - Living room balcony - Passage - Kitchen - Toilet - Bedroom 2 - Bedroom 2 Balcony - Master bedroom 1 - Master bathroom - Master bedroom 2 - MB Balcony
