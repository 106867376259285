import React, { Suspense, useEffect, useState } from 'react'
import { Route, useLocation } from 'wouter'
import {
  useApartmentPreview,
  useEverythingLoaded,
  useLoader,
} from '../../store'
import ApartmentPreview from '../context/ApartmentPreview'
import UserPrompts1 from './UserPrompts1'
import UserPrompts3D from './UserPrompts3D'

export default function LoadingManager() {
  const [previewScreen, setPreviewScreen] = useState(false)
  const [previewModelState, setPreviewModelState] = useState(false)
  const [location, setLocation] = useLocation()
  // const { setLoadedModel } = useLoader()

  useEffect(() => {
    // setLoadedModel('PreviewModel')
    const unsub = useEverythingLoaded.subscribe(
      (paw, previousPaw) => {
        setPreviewScreen(paw)
      },
      (state) => state.agreementAccepted
    )
    const unsub1 = useApartmentPreview.subscribe(
      (paw, previousPaw) => {
        setPreviewModelState(paw)
        console.log('Apartment Preview Model  ' + paw)
      },
      (state) => state.apartmentPreview
    )
    return function cleanup() {
      unsub()
      unsub1()
    }
  }, [])

  return (
    <>
      <Suspense fallback={null}>
        <ApartmentPreview previewScreenVisibility={previewModelState} />
      </Suspense>

      {location !== '/' && location !== '/home' && previewScreen && (
        <Suspense fallback={null}>
          <UserPrompts1 />
        </Suspense>
      )}
      {previewModelState && location === '/home' && (
        <Suspense>
          <UserPrompts3D />
        </Suspense>
      )}
    </>
  )
}
