import { css, StyleSheet } from 'aphrodite'
import React, { useState, useEffect } from 'react'
import { useLocation } from 'wouter'
import {
  useApartmentPreview,
  useCameraControls,
  useGuidedTour,
  useMaster,
  useMenuButtons,
  useTimeOfDaySwitch,
} from '../../store'
import DayLighting from '../assets/Buttons/DayLighting'
import Guide from '../assets/Buttons/Guide'
import Home from '../assets/Buttons/Home'
import Map from '../assets/Buttons/Map'
import MapClose from '../assets/Buttons/MapClose'
import NightLighting from '../assets/Buttons/NightLighting'
import Pause from '../assets/Buttons/Pause'
import Play from '../assets/Buttons/Play'
import MapComponent from './MapComponent'

function Menu() {
  const [location, setLocation] = useLocation()
  const { setCamValues } = useCameraControls()
  const { setLighting } = useTimeOfDaySwitch()
  const [dayTime, setDayTime] = useState(true)
  const [play, setPlay] = useState(false)
  const { guidedTour, setGuidedTour } = useGuidedTour()
  const { setShowGuide } = useMenuButtons()
  const [showMap, setShowMap] = useState(false)
  const { getObject } = useMaster()
  const { setApartmentPreview } = useApartmentPreview()
  const [showComponent, setShowComponent] = useState(false)

  function HomeClick() {
    setLocation('/home')
    setApartmentPreview(true)
    setCamValues({
      position: [],
      lookAt: [16.8999727119186, 20.60282890220478, -5.527502536840995],
    })
    if (guidedTour) {
      setGuidedTour(false)
    }
  }

  function TimeOfDaySwitch() {
    setLighting(!dayTime)
    setDayTime(!dayTime)
  }

  function guidedTourState() {
    setGuidedTour(!play)
  }

  function OnGuideButtonClicked() {
    setShowGuide(true)
  }

  function showMapfunc() {
    setShowMap(!showMap)
  }

  useEffect(() => {
    const unsub = useGuidedTour.subscribe(
      (paw, previousPaw) => {
        setPlay(paw)
      },
      (state) => state.guidedTour
    )

    return function cleanup() {
      unsub()
    }
  })

  useEffect(() => {
    if (location !== '/' && location !== '/home') {
      setTimeout(() => {
        setShowComponent(true)
      }, [1500])
    }
  }, [location])

  return (
    <>
      {showComponent && (
        <>
          <div className={css(styles.bottomLeftContainer)}>
            <button className={css(styles.homeButton)} onClick={HomeClick}>
              <Home />
            </button>
            {/* <button
              className={css(styles.nightButton)}
              onClick={TimeOfDaySwitch}
            >
              {dayTime && <NightLighting />}
              {!dayTime && <DayLighting />}
            </button> */}
            {/* //onClick={guidedTourState} */}
            {/* <button
              className={css(styles.playButton)}
              onClick={guidedTourState}
            >
              {!play && <Play />}
              {play && <Pause />}
            </button> */}
          </div>
          {/* Map button */}
          <button
            className={css(styles.mapButtonContainer)}
            onClick={showMapfunc}
          >
            {!showMap ? <Map /> : <MapClose />}
          </button>

          <div className={css(styles.textContainer)}>
            <div className={css(styles.boldText)}>
              {getObject(location).title}
            </div>
            {/* <div className={css(styles.thinText)}>T1 - 01</div> */}
            <button
              className={css(styles.guideButton)}
              onClick={OnGuideButtonClicked}
            >
              <Guide />
            </button>
          </div>
        </>
      )}
      {/* Map Component */}
      <MapComponent showMap={showMap} setShowMap={setShowMap} />
    </>
  )
}

export default Menu

const styles = StyleSheet.create({
  bottomLeftContainer: {
    position: 'absolute',
    left: 24,
    bottom: 56,
    display: ' flex',
    flexWrap: 'nowrap',
    '@media(max-width:576px) and (orientation: landscape)': {
      left: 14,
      bottom: 14,
    },
    '@media(min-width:576px) and (orientation: landscape)': {
      left: 14,
      bottom: 14,
    },
    '@media(min-width: 768px) and (orientation: landscape)': {
      left: 14,
      bottom: 14,
    },
    '@media(min-width: 992px) and (orientation: landscape)': {
      left: 14,
      bottom: 18,
    },
    '@media(min-width: 1200px)': {
      left: 14,
      bottom: 18,
    },
    '@media(min-width: 1400px)': {
      left: 24,
      bottom: 56,
    },
    '@media(min-width: 2560px)': {
      left: 44,
      bottom: 56,
    },
  },
  homeButton: {
    padding: 0,
    outline: 'none',
    background: 'none',
    border: 'none',
    width: 48,
    height: 48,
    marginLeft: 8,
    marginRight: 8,
    ':hover': {
      cursor: 'pointer',
    },
    '@media(max-width:576px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width:576px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width: 768px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width: 992px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width: 1200px)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width: 1400px)': {
      transform: 'scale(1)',
      marginLeft: 8,
      marginRight: 8,
    },
    '@media(min-width: 2560px)': {
      transform: 'scale(1.5)',
      marginLeft: 28,
      marginRight: 28,
    },
  },
  nightButton: {
    padding: 0,
    outline: 'none',
    background: 'none',
    border: 'none',
    width: 48,
    height: 48,
    marginLeft: 8,
    marginRight: 8,
    ':hover': {
      cursor: 'pointer',
    },
    '@media(max-width:576px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width:576px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width: 768px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width: 992px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width: 1200px)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width: 1400px)': {
      transform: 'scale(1)',
      marginLeft: 8,
      marginRight: 8,
    },
    '@media(min-width: 2560px)': {
      transform: 'scale(1.5)',
      marginLeft: 28,
      marginRight: 28,
    },
  },
  playButton: {
    padding: 0,
    outline: 'none',
    background: 'none',
    border: 'none',
    width: 48,
    height: 48,
    marginLeft: 8,
    marginRight: 8,
    ':hover': {
      cursor: 'pointer',
    },
    '@media(max-width:576px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width:576px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width: 768px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width: 992px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width: 1200px)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width: 1400px)': {
      transform: 'scale(1)',
      marginLeft: 8,
      marginRight: 8,
    },
    '@media(min-width: 2560px)': {
      transform: 'scale(1.5)',
      marginLeft: 28,
      marginRight: 28,
    },
  },
  mapButtonContainer: {
    position: 'absolute',
    right: 35,
    bottom: 56,
    width: 48,
    height: 48,
    padding: 0,
    outline: 'none',
    background: 'none',
    border: 'none',
    pointerEvents: 'auto',
    ':hover': {
      cursor: 'pointer',
    },
    '@media(max-width:576px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      right: 15,
      bottom: 14,
    },
    '@media(min-width:576px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      right: 15,
      bottom: 14,
    },
    '@media(min-width: 768px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      right: 15,
      bottom: 14,
    },
    '@media(min-width: 992px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      right: 15,
      bottom: 18,
    },
    '@media(min-width: 1200px)': {
      transform: 'scale(0.8)',
      right: 15,
      bottom: 18,
    },
    '@media(min-width: 1400px)': {
      transform: 'scale(1)',
      right: 35,
      bottom: 56,
    },
    '@media(min-width: 2560px)': {
      transform: 'scale(1.5)',
      right: 55,
      bottom: 56,
    },
  },
  textContainer: {
    position: 'absolute',
    top: 35,
    right: 32,
    textAlign: 'right',
    '@media(max-width:576px) and (orientation: landscape)': {
      right: 15,
      top: 8,
    },
    '@media(min-width:576px) and (orientation: landscape)': {
      right: 15,
      top: 8,
    },
    '@media(min-width: 768px) and (orientation: landscape)': {
      right: 15,
      top: 8,
    },
    '@media(min-width: 992px) and (orientation: landscape)': {
      right: 15,
      top: 18,
    },
    '@media(min-width: 1200px)': {
      right: 15,
      top: 18,
    },
    '@media(min-width: 1400px)': {
      top: 35,
      right: 32,
    },
    '@media(min-width: 2560px)': {
      right: 55,
    },
  },
  boldText: {
    fontSize: 32,
    lineHeight: '38px',
    fontFamily: 'DIN-Bold',
    color: 'white',
    '@media(max-width:576px) and (orientation: landscape)': {
      fontSize: 16,
      lineHeight: '19px',
    },
    '@media(min-width:576px) and (orientation: landscape)': {
      fontSize: 16,
      lineHeight: '19px',
    },
    '@media(min-width: 768px) and (orientation: landscape)': {
      fontSize: 20,
    },
    '@media(min-width: 992px) and (orientation: landscape)': {
      fontSize: 20,
    },
    '@media(min-width: 1200px)': {
      fontSize: 20,
    },
    '@media(min-width: 1400px)': {
      fontSize: 32,
    },
    '@media(min-width: 2560px)': {
      fontSize: 38,
      lineHeight: '46px',
    },
  },
  thinText: {
    fontSize: 24,
    lineHeight: '28px',
    fontFamily: 'DIN-Regular',
    color: 'white',
    '@media(max-width:576px) and (orientation: landscape)': {
      fontSize: 16,
      lineHeight: '19.2px',
    },
    '@media(min-width:576px) and (orientation: landscape)': {
      fontSize: 16,
      lineHeight: '19.2px',
    },
    '@media(min-width: 768px) and (orientation: landscape)': {
      fontSize: 16,
      lineHeight: '19.2px',
    },
    '@media(min-width: 992px) and (orientation: landscape)': {
      fontSize: 16,
      lineHeight: '19.2px',
    },
    '@media(min-width: 1200px)': {
      fontSize: 16,
      lineHeight: '19.2px',
    },
    '@media(min-width: 1400px)': {
      fontSize: 24,
      lineHeight: '28px',
    },
    '@media(min-width: 2560px)': {
      fontSize: 28,
    },
  },
  guideButton: {
    width: 48,
    height: 48,
    marginTop: 28,
    padding: 0,
    outline: 'none',
    background: 'none',
    border: 'none',
    ':hover': {
      cursor: 'pointer',
    },
    '@media(max-width:576px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      marginTop: 51,
    },
    '@media(min-width:576px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      marginTop: 51,
    },
    '@media(min-width: 768px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      marginTop: 51,
    },
    '@media(min-width: 992px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      marginTop: 18,
    },
    '@media(min-width: 1200px)': {
      transform: 'scale(0.8)',
      marginTop: 18,
    },
    '@media(min-width: 1400px)': {
      marginTop: 28,
      transform: 'scale(1)',
    },
    '@media(min-width: 2560px)': {
      transform: 'scale(1.5)',
      marginTop: 88,
    },
  },
})
