import React from 'react'

function ViewHS() {
    return (
        <svg viewBox="0 0 40 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.6069 0.0820808L17.4255 0.160843L16.149 0.406963L14.8724 0.653088L13.6385 1.06001C12.4276 1.46037 12.3849 1.47678 11.2889 1.99857C10.2092 2.51378 10.137 2.55644 9.07376 3.24231C8.00722 3.93145 7.94487 3.9774 7.00632 4.77812C6.07433 5.569 6.00541 5.63463 5.185 6.54365C4.35475 7.46251 4.31208 7.5183 3.60325 8.55201L2.87144 9.61527L2.25121 10.8525L1.6277 12.0896L1.18467 13.3859L0.744934 14.6821L0.462712 16.1261L0.180491 17.57L0.0918861 19.0303L0 20.4907L0.0590697 21.4751C0.114858 22.4006 0.131266 22.522 0.315038 23.5229C0.488965 24.4713 0.544753 24.7043 0.823693 25.6396C2.83534 32.3702 8.55525 39.8557 18.3116 48.5258C19.2206 49.3331 20.5694 49.402 21.5145 48.6866C21.9116 48.3879 24.655 45.8512 25.9381 44.5944C32.2192 38.4511 36.295 32.974 38.3821 27.8744C38.7694 26.9325 38.8908 26.588 39.1665 25.6757C39.4585 24.6912 39.5078 24.4877 39.6882 23.5098C39.872 22.4892 39.8884 22.371 39.9409 21.4587L40 20.4907L39.9081 18.9975L39.8129 17.5044L39.5242 16.0604L39.2387 14.6165L38.7956 13.3367L38.3559 12.0568L37.7455 10.8426L37.1384 9.6284L36.4066 8.55858C35.6912 7.52158 35.6485 7.46579 34.8183 6.54693C33.9913 5.63135 33.9355 5.57556 32.9806 4.76499C32.0256 3.95771 31.9632 3.90848 30.9131 3.23246C29.8696 2.55644 29.7908 2.51378 28.7144 1.99857C27.6216 1.47678 27.5757 1.46037 26.3615 1.06001L25.121 0.649807L23.8641 0.406963L22.6073 0.164124L21.2946 0.0820808C20.5759 0.0361366 19.9426 4.19617e-05 19.8868 4.19617e-05C19.8343 0.0033226 19.2567 0.0394211 18.6069 0.0820808Z" fill="#850C70" fill-opacity="0.9"/>
        <path d="M17.2814 22.6406C17.2814 23.3368 17.5579 24.0045 18.0502 24.4968C18.5425 24.9891 19.2102 25.2656 19.9064 25.2656C20.6026 25.2656 21.2703 24.9891 21.7625 24.4968C22.2548 24.0045 22.5314 23.3368 22.5314 22.6406C22.5314 21.9444 22.2548 21.2768 21.7625 20.7845C21.2703 20.2922 20.6026 20.0156 19.9064 20.0156C19.2102 20.0156 18.5425 20.2922 18.0502 20.7845C17.5579 21.2768 17.2814 21.9444 17.2814 22.6406ZM30.0829 22.0359C27.8611 17.3555 24.5025 15 20.0001 15C15.4954 15 12.1392 17.3555 9.91732 22.0383C9.8282 22.227 9.78198 22.4331 9.78198 22.6418C9.78198 22.8505 9.8282 23.0566 9.91732 23.2453C12.1392 27.9258 15.4978 30.2813 20.0001 30.2813C24.5048 30.2813 27.8611 27.9258 30.0829 23.243C30.2634 22.8633 30.2634 22.4227 30.0829 22.0359ZM19.9064 26.7656C17.6283 26.7656 15.7814 24.9188 15.7814 22.6406C15.7814 20.3625 17.6283 18.5156 19.9064 18.5156C22.1845 18.5156 24.0314 20.3625 24.0314 22.6406C24.0314 24.9188 22.1845 26.7656 19.9064 26.7656Z" fill="white"/>
        </svg>
        
    )
}

export default ViewHS
