import React from 'react'

function MapMarker(props) {
    // const {getObject} = useMaster()
    return (
        <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path  d="M3.575 34.3754C4.1875 41.0504 6.95 47.1004 11.5625 51.8629C15.8625 56.3129 21.475 59.2004 27.7125 60.1879L28.25 60.2754L28.25 58.7004C28.25 57.1754 28.2375 57.1254 27.9625 57.0754C27.8125 57.0504 26.95 56.8504 26.0375 56.6379C17.0875 54.5754 9.7125 47.3504 7.45 38.4379C6.8375 35.9879 6.7 34.8629 6.7 32.0629C6.7 29.2629 6.8375 28.1379 7.45 25.6879C9.7125 16.7754 17.0875 9.55039 26.0375 7.48789C26.95 7.27539 27.8125 7.07539 27.9625 7.05039C28.2375 7.00039 28.25 6.95039 28.25 5.42539L28.25 3.8504L27.7125 3.93789C24.7875 4.40039 22.05 5.26289 19.4375 6.55039C16.3125 8.1004 13.9125 9.8379 11.5625 12.2629C7.425 16.5254 4.85 21.6504 3.8625 27.5629C3.625 29.0254 3.45 33.0129 3.575 34.3754Z" fill="white"/>
            <path  d="M36.0883 60.1879C39.7133 59.6754 43.7758 58.1879 46.9633 56.2129C50.3258 54.1254 53.5758 50.9379 55.7883 47.5629C56.7258 46.1129 58.2383 43.0504 58.7883 41.4254C59.9008 38.2129 60.3008 35.7004 60.3008 32.0629C60.3008 29.1129 60.1633 27.8004 59.5383 25.2629C58.1133 19.3629 54.5883 13.8129 49.8133 9.96289C45.9008 6.81289 40.9508 4.62539 36.0883 3.93789L35.5008 3.8504L35.5008 5.4129L35.5008 6.98789L35.9633 7.06289C45.8633 8.7129 54.0008 16.1379 56.4258 25.6879C57.0383 28.1379 57.1758 29.2629 57.1758 32.0629C57.1758 34.8629 57.0383 35.9879 56.4258 38.4379C54.1758 47.3004 46.8508 54.5129 37.9633 56.6129C37.1258 56.8129 36.2258 57.0129 35.9633 57.0629L35.5008 57.1379L35.5008 58.7129L35.5008 60.2754L36.0883 60.1879Z" fill="white"/>
            <path  d="M32 13.625C42.1482 13.625 50.375 21.8517 50.375 32C50.375 42.1482 42.1482 50.375 32 50.375C21.8518 50.375 13.625 42.1482 13.625 32C13.625 21.8517 21.8518 13.625 32 13.625Z" stroke="white" stroke-width="6"/>
        </svg>
    )
}

export default MapMarker
