import { css, StyleSheet } from 'aphrodite'
import React, { useEffect } from 'react'
import BirlaEstateLogo from '../assets/logo/BirlaEstatesLogo.webp'

export default function LoadingScreenBranding(props) {
  //
  return (
    <div
      className={css(
        props.loadingCompleted && !props.portrait
          ? styles.animate
          : styles.beforeAnimate,
        styles.ImageContainer
      )}
    >
      <img
        src={BirlaEstateLogo}
        alt="logo"
        className={css(styles.logoSize)}
      ></img>
    </div>
  )
}

const moveUp1600 = {
  from: {
    marginTop: 230,
  },
  to: {
    marginTop: 30,
  },
}

const moveUp1400 = {
  from: {
    marginTop: 230,
  },
  to: {
    marginTop: 40,
  },
}

const moveUp1200 = {
  from: {
    marginTop: 200,
  },
  to: {
    marginTop: 40,
  },
}

const moveUp992 = {
  from: {
    marginTop: 150,
  },
  to: {
    marginTop: 30,
  },
}

const moveUp768 = {
  from: {
    marginTop: 230,
  },
  to: {
    marginTop: 30,
  },
}

const moveUpPortrait768 = {
  from: {
    marginTop: 230,
  },
  to: {
    marginTop: 30,
  },
}

const moveUp576 = {
  from: {
    marginTop: 70,
  },
  to: {
    marginTop: 20,
  },
}

const moveUp320 = {
  from: {
    marginTop: 70,
  },
  to: {
    marginTop: 50,
  },
}

const moveUp = {
  from: {
    marginTop: 150,
  },
  to: {
    marginTop: 40,
  },
}

const styles = StyleSheet.create({
  ImageContainer: {
    zIndex: 201,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  logoSize: {
    zIndex: 201,
    width: 100,
    height: 'auto',
    '@media (max-width: 576px) and (orientation:portrait)': {
      width: 84,
      height: 'auto',
    },
    '@media (min-width: 576px) and (orientation:landscape)': {
      width: 84,
      height: 'auto',
    },

    // Medium devices (tablets, 768px and up)
    '@media (min-width: 768px) and (orientation:portrait)': {
      width: 200,
      height: 'auto',
    },
    '@media (min-width: 768px) and (orientation:landscape)': {
      width: 84,
      height: 'auto',
    },

    // Large devices (desktops, 992px and up)
    '@media (min-width: 992px)': {
      width: 200,
      height: 'auto',
    },

    // X-Large devices (large desktops, 1200px and up)
    '@media (min-width: 1200px)': {
      width: 200,
      height: 'auto',
    },

    // XX-Large devices (larger desktops, 1400px and up)
    '@media (min-width: 1400px)': {
      width: 256,
      height: 346.75,
    },
    '@media (min-width: 1920px)': {
      width: 356,
      height: 446.75,
    },
    '@media(min-width:2560px)': {
      width: 456,
      height: 546.75,
    },
  },
  animate: {
    zIndex: 201,
    animationName: [moveUp],
    animationDuration: '2s',
    animationFillMode: 'forwards',
    '@media (min-width:320px) and (orientation:landscape)': {
      animationName: [moveUp320],
    },
    '@media (min-width: 576px)': {
      animationName: [moveUp576],
    },

    // Medium devices (tablets, 768px and up)
    '@media (min-width: 768px)': {
      animationName: [moveUp768],
    },

    // Large devices (desktops, 992px and up)
    '@media (min-width: 992px)': {
      animationName: [moveUp992],
    },

    // X-Large devices (large desktops, 1200px and up)
    '@media (min-width: 1200px)': {
      animationName: [moveUp1200],
    },
    // XX-Large devices (larger desktops, 1400px and up)
    '@media (min-width: 1400px)': {
      animationName: [moveUp1400],
    },
    '@media(min-width:1600px)': { animationName: [moveUp1600] },
    '@media(min-width:2560px)': {},
  },
  beforeAnimate: {
    zIndex: 201,
    marginTop: 150,
    '@media (min-width:320px) and (orientation:landscape)': {
      marginTop: 70,
    },
    '@media (max-width: 576px) and (orientation:portrait)': {
      marginTop: 222,
    },
    '@media (min-width: 576px)': {
      marginTop: 50,
    },
    // Medium devices (tablets, 768px and up)
    '@media (min-width: 768px) and (orientation:landscape)': { marginTop: 50 },

    '@media (min-width: 768px) and (orientation:portrait)': { marginTop: 200 },

    // Large devices (desktops, 992px and up)
    '@media (min-width: 992px)': { marginTop: 150 },

    // X-Large devices (large desktops, 1200px and up)
    '@media (min-width: 1200px)': {
      marginTop: 200,
    },
    // XX-Large devices (larger desktops, 1400px and up)
    '@media (min-width: 1400px)': {
      marginTop: 230,
    },
    '@media(min-width:1600px)': {},
    '@media(min-width:2560px)': {},
  },
})
