import { css, StyleSheet } from 'aphrodite'
import React, { useEffect, useState } from 'react';
import { useApartmentPreview, useCameraControls, useGuidedTour, useHotspotState, useMenuButtons } from '../../store';
import Branding from '../core/Branding';
import { fadeIn, fadeOut } from 'react-animations';
import Congo from '../assets/images/Congo.png';
import { useLocation } from 'wouter';

function Congratulations() {

    const [componentVisibility, setComponentVisibility] = useState(false);
    const [fade, setFade] = useState(false)
    const {setHotspotVisibility} = useHotspotState()
    const {setGuidedTour, setTourCompleted} = useGuidedTour();
    const [location, setLocation] = useLocation()
    const {setCamValues} = useCameraControls()
    const {setApartmentPreview} = useApartmentPreview()

    useEffect(() => {
        const unsub = useGuidedTour.subscribe((paw, previousePaw) => {setHotspotVisibility(!paw) ;setComponentVisibility(paw)}, state => state.tourCompleted)

        return function cleaup(){
            unsub();
        }
    },[])

    function animationEnd(){
        if(fade){
            setComponentVisibility(false)
            setFade(false)
        }
        setTourCompleted(false)
    }

    function onReplayClicked(){
        if(!fade){
            setFade(true);
            setHotspotVisibility(true)
        }
        setGuidedTour(true);
    }

    function onHomeButtonClicked(){
        if(!fade){
            setFade(true);
            setHotspotVisibility(true)
        }
        setLocation('/home')
        setCamValues({position:[], lookAt:[16.8999727119186, 20.60282890220478, -5.527502536840995]})
        setApartmentPreview(true)
    }

    return (
        <div className={css(componentVisibility ? styles.guideContainer : styles.displayNone, fade ? styles.screenfadeOut : styles.screenfadeIn)} onAnimationEnd={animationEnd}>
            <Branding/>
            <div className={css(styles.textContainer)}>
                <div className={css(styles.iconSize)}>
                    <img className={css(styles.imageSize)} src={Congo} alt="Congratulations"></img>
                </div>
                <div className={css(styles.HeaderText)}>
                Congratulations!
                </div>
                <div className={css(styles.SubheaderText)}>
                You have completed the walkthrough of apartment 2 BHK A!
                </div>
            </div>
            <div className={css(styles.buttonContainer)}>
                <button className={css(styles.buttonstyle)} onClick={onReplayClicked}>
                    REPLAY
                </button>
                <button className={css(styles.buttonstyle)} onClick={onHomeButtonClicked}>
                    BACK TO PREVIEW
                </button>
            </div>
        </div>
    )
}

export default Congratulations

const styles = StyleSheet.create({
    guideContainer:{
        position:'absolute',
        top:0,
        bottom:0,
        left:0,
        right:0,
        backgroundColor:'#850C70E5',
        textAlign:'center',
    },
    textContainer:{
    },
    iconSize:{
        // width:20,
        // height:20,
        marginLeft:'auto',
        marginRight:'auto',
        '@media(min-width:556px) and (orientation:landscape)': {
            marginTop:37.5
        },
        // Medium devices (tablets, 768px and up)
        '@media (min-width: 768px) and (orientation:landscape)' :{
            marginTop:37.5
        },
        // Large devices (desktops, 992px and up)
        '@media(min-width: 992px) and (orientation:landscape)':{
            marginTop:130
        },
        // X-Large devices (large desktops, 1200px and up)
        '@media (min-width: 1200px)': {
            // width:170,
            // height:'auto',
            marginTop:130
        },
        // XX-Large devices (larger desktops, 1400px and up)
        '@media (min-width: 1400px)':{
            // width:190,
            // height:'auto',
            marginTop:130
        },
        '@media (min-width: 2560px)':{
            marginTop:350
        }
    },
    imageSize: {
        width:20,
        height:20,
        '@media(min-width:556px) and (orientation:landscape)': {
            width:64,
            height:64,
        },
        // Medium devices (tablets, 768px and up)
        '@media (min-width: 768px) and (orientation:landscape)' :{
            width:64,
            height:64,
        },
        // Large devices (desktops, 992px and up)
        '@media(min-width: 992px) and (orientation:landscape)':{
            width:150,
            height:'auto',
        },
        // X-Large devices (large desktops, 1200px and up)
        '@media (min-width: 1200px)': {
            width:170,
            height:'auto',
            // marginTop:130
        },
        // XX-Large devices (larger desktops, 1400px and up)
        '@media (min-width: 1400px)':{
            width:190,
            height:'auto',
            // marginTop:130
        },
        '@media (min-width: 2560px)':{
            width:210,
            height:'auto'
        }
    },
    HeaderText:{
        marginLeft:'auto',
        marginRight:'auto',
        fontFamily:'DIN-Bold',
        color:'#ffffff',
        '@media(min-width:556px) and (orientation:landscape)': {
            fontSize:32,
            lineHeight: '40px',
            marginTop:7.5
        },
        // Medium devices (tablets, 768px and up)
        '@media (min-width: 768px) and (orientation:landscape)' :{
            fontSize:32,
            lineHeight: '40px',
            marginTop:7.5
        },
        // Large devices (desktops, 992px and up)
        '@media(min-width: 992px) and (orientation:landscape)':{
            fontSize:60,
            lineHeight:'73px',
            marginTop:26
        },
        // X-Large devices (large desktops, 1200px and up)
        '@media (min-width: 1200px)': {
            fontSize:60,
            lineHeight:'73px',
            marginTop:26
        },
        // XX-Large devices (larger desktops, 1400px and up)
        '@media (min-width: 1400px)':{
            fontSize:64,
            lineHeight:'77px',
            marginTop:30
        },
        '@media (min-width: 1600px)':{
            
        },
        '@media (min-width: 2560px)':{
            fontSize:72,
            lineHeight:'86px',
            marginTop:38
        }
    },
    SubheaderText:{
        marginLeft:'auto',
        marginRight:'auto',
        fontFamily:'DIN-Regular',
        color:'#ffffff',
        '@media(min-width:556px) and (orientation:landscape)': {
            fontSize:12,
            lineHeight:'14px',
        },
        // Medium devices (tablets, 768px and up)
        '@media (min-width: 768px) and (orientation:landscape)' :{
            fontSize:12,
            lineHeight:'14px',
        },
        // Large devices (desktops, 992px and up)
        '@media(min-width: 992px) and (orientation:landscape)':{
            fontSize:24,
            lineHeight:'30px',
            width:500
        },
        // X-Large devices (large desktops, 1200px and up)
        '@media (min-width: 1200px)': {
            fontSize:28,
            lineHeight:'34px',
            width:500
        },
        // XX-Large devices (larger desktops, 1400px and up)
        '@media (min-width: 1400px)':{
            fontSize:32,
            lineHeight:'38px',
            width:500
        },
        '@media (min-width: 1600px)':{
            
        },
        '@media (min-width: 2560px)':{
            fontSize:42,
            lineHeight:'66px',
            width:700
        }
    },
    buttonContainer:{
        display:'flex',
        flexWrap:'nowrap',
        marginRight:'auto',
        marginLeft:'auto',
        width:'fit-content',
        marginTop:58.54,
        '@media(min-width:556px) and (orientation:landscape)': {
            marginTop:37.69,
        },
        // Medium devices (tablets, 768px and up)
        '@media (min-width: 768px) and (orientation:landscape)' :{
            marginTop:37.69,
        },
        // Large devices (desktops, 992px and up)
        '@media(min-width: 992px) and (orientation:landscape)':{
            marginTop:58.54,
        },
        // X-Large devices (large desktops, 1200px and up)
        '@media (min-width: 1200px)': {
            marginTop:58.54,
        },
        // XX-Large devices (larger desktops, 1400px and up)
        '@media (min-width: 1400px)':{
            marginTop:58.54,
        },
        '@media (min-width: 2560px)':{
            marginTop:88.54,
        },
    },
    buttonstyle:{
        marginLeft:48,
        marginRight:48,
        backgroundColor:'#FFFFFFCC',
        border:'1px solid #FFFFFF',
        width:224,
        height:68,
        color:'#850C70',
        fontFamily:'DIN-Regular',
        lineHeight:'26px',
        fontSize:22,
        '@media(min-width:556px) and (orientation:landscape)': {
            width:110,
            height:31,
            fontSize: 12,
            lineHeight: '14px'
        },
        // Medium devices (tablets, 768px and up)
        '@media (min-width: 768px) and (orientation:landscape)' :{
            width:110,
            height:31,
            fontSize: 12,
            lineHeight: '14px'
        },
        // Large devices (desktops, 992px and up)
        '@media(min-width: 992px) and (orientation:landscape)':{
            width:224,
            height:68,
            lineHeight:'26px',
            fontSize:22,
        },
        // X-Large devices (large desktops, 1200px and up)
        '@media (min-width: 1200px)': {
            width:224,
            height:68,
            lineHeight:'26px',
            fontSize:22,
        },
        // XX-Large devices (larger desktops, 1400px and up)
        '@media (min-width: 1400px)':{
            width:224,
            height:68,
            lineHeight:'26px',
            fontSize:22,
        },
        '@media (min-width: 2560px)':{
            width:294,
            height:88,
            lineHeight:'34px',
            fontSize:30,
        },
    },
    screenfadeIn: {
        animationName:[fadeIn],
        animationDuration:'1s'
    },
    screenfadeOut: {
        animationName:fadeOut,
        animationDuration:'1s',
    },
})
