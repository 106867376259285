import React, { useEffect, useState } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { ApartmentInfoText } from '../../constants'
import {
  useApartmentPreview,
  useCameraControls,
  useGuidedTour,
  useHotspotState,
  useMenuButtons,
  useTutorial,
} from '../../store'
import { fadeIn, fadeOut } from 'react-animations'
import Guide from '../assets/Buttons/Guide'
import UserPrompts from '../core/UserPrompts'
import { useLocation } from 'wouter'
import Map from '../assets/Buttons/Map'
import MapClose from '../assets/Buttons/MapClose'
import KeyPlan from '../assets/images/KeyPlan.png'
import { isMobile } from 'react-device-detect'

export default function ApartmentPreviewUI() {
  const [state, setState] = useState({
    componentVisibility: false,
    isFadeOut: true,
  })
  const { setGuidedTour } = useGuidedTour()
  const { setShowHint } = useTutorial()
  const { setApartmentPreview } = useApartmentPreview()
  const [location, setLocation] = useLocation()
  const [showMap, setShowMap] = useState(false)
  const { setHotspotVisibility } = useHotspotState()

  useEffect(() => {
    setState({
      ...state,
      componentVisibility: true,
      isFadeOut: false,
    })
    // const unsub = useApartmentPreview.subscribe((paw, previousPaw) => { setState({ componentVisibility:paw, isFadeOut:!paw }) }, state => state.apartmentPreview)

    // return function cleanup(){
    //     unsub();
    // }
  }, [])

  function StartGuidedTour() {
    // setGuidedTour(true)
    setApartmentPreview(false)
    setLocation('/livingroom1')
  }

  function OnGuideButtonClicked() {
    setShowHint(true)
  }

  function showMapfunc() {
    if (showMap) {
      setHotspotVisibility(true)
    } else {
      setHotspotVisibility(false)
    }
    setShowMap(!showMap)
  }

  return (
    <>
      <div
        style={{ opacity: 0 }}
        className={css(
          state.componentVisibility
            ? showMap || isMobile
              ? styles.textContainer1
              : styles.textContainer
            : styles.displayNone,
          state.isFadeOut ? styles.screenfadeOut : styles.screenfadeIn
        )}
      >
        <div className={css(styles.headerText)}>{ApartmentInfoText.type}</div>
        <div className={css(styles.subHeaderText)}>
          {ApartmentInfoText.tower}
        </div>
      </div>

      <button
        onClick={StartGuidedTour}
        style={{ opacity: 0 }}
        className={css(
          state.componentVisibility
            ? styles.buttonContainer
            : styles.displayNone,
          state.isFadeOut ? styles.screenfadeOut : styles.screenfadeIn
        )}
      >
        {ApartmentInfoText.tour}
      </button>

      <button
        style={{ opacity: 0 }}
        className={css(
          state.componentVisibility
            ? styles.guideContainer
            : styles.displayNone,
          state.isFadeOut ? styles.screenfadeOut : styles.screenfadeIn
        )}
        onClick={OnGuideButtonClicked}
      >
        <Guide />
      </button>
      <UserPrompts />
      {/* <button className={css(styles.mapButtonContainer)} onClick={showMapfunc}>
        {!showMap ? <Map /> : <MapClose />}
      </button> */}
      <div
        className={css(showMap ? styles.keyplanContainer : styles.displayNone)}
      >
        <img
          alt="Key Plan 2BHK"
          src={KeyPlan}
          className={css(styles.mapSize)}
        />
      </div>
      {/* <button className={css(state.componentVisibility ? styles.planViewContainer: styles.displayNone, state.isFadeOut ? styles.screenfadeOut : styles.screenfadeIn)} onClick={() => {planView()}} >
                <PlanView/>
            </button> */}
    </>
  )
}

const styles = StyleSheet.create({
  textContainer: {
    position: 'absolute',
    top: 41,
    left: '50%',
    transform: 'translateX(-50%)',
    textAlign: 'center',
    '@media(min-width:556px) and (orientation:landscape)': {
      top: 27,
    },
    '@media(min-width:992px) and (orientation:landscape)': {
      top: 41,
    },
    '@media(min-width:1200px)': {
      top: 41,
    },
  },
  textContainer1: {
    position: 'absolute',
    zIndex: 100,
    // animationName:[fadeIn],
    // animationDuration:'0.5s',
    top: 41,
    right: 15,
    textAlign: 'center',
    '@media(min-width:556px) and (orientation:landscape)': {
      top: 8,
      right: 15,
    },
    '@media(min-width:992px) and (orientation:landscape)': {
      top: 8,
      right: 15,
    },
    '@media(min-width:1200px)': {
      top: 8,
      right: 15,
    },
  },
  headerText: {
    fontFamily: 'DIN-Bold',
    color: '#E5BD79',
    fontSize: 48,
    lineHeight: '58px',
    '@media(min-width:556px) and (orientation:landscape)': {
      fontSize: 16,
      lineHeight: '19px',
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      fontSize: 16,
      lineHeight: '19px',
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      fontSize: 38,
      lineHeight: '48px',
    },
    '@media(min-width: 1200px)': {
      fontSize: 42,
      lineHeight: '54px',
    },
    '@media(min-width: 1400px)': {
      fontSize: 48,
      lineHeight: '58px',
    },
    '@media(min-width: 1600px)': {
      fontSize: 48,
      lineHeight: '58px',
    },
    '@media(min-width: 2560px)': {
      fontSize: 56,
      lineHeight: '65px',
    },
  },
  subHeaderText: {
    fontFamily: 'DIN-Regular',
    color: '#E5BD79',
    fontSize: 48,
    lineHeight: '58px',
    '@media(min-width:556px) and (orientation:landscape)': {
      fontSize: 12,
      lineHeight: '14px',
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      fontSize: 12,
      lineHeight: '14px',
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      fontSize: 28,
      lineHeight: '32px',
    },
    '@media(min-width: 1200px)': {
      fontSize: 32,
      lineHeight: '36px',
    },
    '@media(min-width: 1400px)': {
      fontSize: 24,
      lineHeight: '28px',
    },
    '@media(min-width: 1600px)': {
      fontSize: 24,
      lineHeight: '28px',
    },
    '@media(min-width: 2560px)': {
      fontSize: 32,
      lineHeight: '36px',
    },
  },
  buttonContainer: {
    width: 307,
    height: 68,
    backgroundColor: '#ffffffCC',
    position: 'absolute',
    bottom: 46,
    left: '50%',
    transform: 'translateX(-50%)',
    border: '1px solid #ffffffE6',
    fontFamily: 'DIN-Regular',
    color: '#850C70',
    ':hover': {
      cursor: 'pointer',
    },
    '@media(min-width:556px) and (orientation:landscape)': {
      width: 173,
      height: 31,
      fontSize: 12,
      lineHeight: '14.06px',
      bottom: 18,
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      width: 173,
      height: 31,
      fontSize: 12,
      lineHeight: '14.06px',
      bottom: 18,
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      width: 307,
      height: 68,
      fontSize: 22,
      lineHeight: '28px',
      bottom: 46,
    },
    '@media(min-width: 1200px)': {
      width: 307,
      height: 68,
      fontSize: 22,
      lineHeight: '28px',
    },
    '@media(min-width: 1400px)': {
      width: 240,
      height: 53.16,
      fontSize: 16,
      lineHeight: '18.75px',
      bottom: 53.84,
    },
    '@media(min-width: 1600px)': {
      width: 307,
      height: 68,
      fontSize: 24,
      lineHeight: '28px',
    },
    '@media(min-width: 2560px)': {
      width: 407,
      height: 88,
      fontSize: 32,
      lineHeight: '36px',
    },
  },
  planViewContainer: {
    width: 48,
    height: 48,
    position: 'absolute',
    bottom: 78,
    left: 96,
    background: 'none',
    border: 'none',
    outline: 0,
    padding: 0,
    '@media(min-width:556px) and (orientation:landscape)': {
      width: 48,
      height: 48,
      bottom: 78,
      left: 96,
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      width: 48,
      height: 48,
      bottom: 78,
      left: 96,
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      width: 48,
      height: 48,
      bottom: 78,
      left: 96,
    },
    '@media(min-width: 1200px)': {
      width: 48,
      height: 48,
      bottom: 78,
      left: 96,
    },
    '@media(min-width: 1400px)': {
      width: 48,
      height: 48,
      bottom: 78,
      left: 96,
    },
    '@media(min-width: 1600px)': {
      width: 48,
      height: 48,
      bottom: 78,
      left: 96,
    },
  },
  guideContainer: {
    width: 48,
    height: 48,
    position: 'absolute',
    bottom: 56,
    left: 96,
    background: 'none',
    border: 'none',
    outline: 0,
    padding: 0,
    ':hover': {
      cursor: 'pointer',
    },
    '@media(min-width:556px) and (orientation:landscape)': {
      width: 32,
      height: 32,
      bottom: 18,
      left: 15,
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      width: 38,
      height: 38,
      bottom: 18,
      left: 15,
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      width: 48,
      height: 48,
      bottom: 56,
      left: 32,
    },
    '@media(min-width: 1200px)': {
      width: 48,
      height: 48,
      bottom: 56,
      left: 32,
    },
    '@media(min-width: 1400px)': {
      width: 48,
      height: 48,
      bottom: 53.84,
      left: 32,
    },
    '@media(min-width: 1600px)': {
      width: 48,
      height: 48,
      bottom: 56,
      left: 32,
    },
    '@media(min-width: 2560px)': {
      width: 64,
      height: 64,
      bottom: 56,
      left: 32,
    },
  },
  displayNone: {
    display: 'none',
    pointerEvents: 'none',
  },
  screenfadeIn: {
    animationName: {
      from: { opacity: 0 },
      to: { opacity: 1 },
    },
    '-moz-animation': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    } /* Firefox */,
    '-webkit-animation': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    } /* Safari and Chrome */,
    '-o-animation': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    } /* Opera */,

    animationFillMode: 'forwards',
    '-webkit-animation-fill-mode': 'forwards',
    animationDuration: '1s',
    '-moz-animation-duration': '1s' /* Firefox */,
    '-webkit-animation-duration': '1s' /* Safari and Chrome */,
    '-o-animation-duration': '1s' /* Opera */,
    animationDelay: '0.5s',
    '-webkit-animation-delay': '0.5s' /* Safari and Chrome */,
  },
  screenfadeOut: {
    animationName: fadeOut,
    animationDuration: '1s',
  },
  mapButtonContainer: {
    position: 'absolute',
    right: 35,
    bottom: 56,
    width: 48,
    height: 48,
    padding: 0,
    outline: 'none',
    background: 'none',
    border: 'none',
    pointerEvents: 'auto',
    zIndex: 100,
    ':hover': {
      cursor: 'pointer',
    },
    '@media(max-width:576px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      right: 15,
      bottom: 14,
    },
    '@media(min-width:576px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      right: 15,
      bottom: 14,
    },
    '@media(min-width: 768px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      right: 15,
      bottom: 14,
    },
    '@media(min-width: 992px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      right: 15,
      bottom: 18,
    },
    '@media(min-width: 1200px)': {
      transform: 'scale(0.8)',
      right: 15,
      bottom: 18,
    },
    '@media(min-width: 1400px)': {
      transform: 'scale(1)',
      right: 35,
      bottom: 56,
    },
    '@media(min-width: 2560px)': {
      transform: 'scale(1.5)',
      right: 55,
      bottom: 56,
    },
  },
  keyplanContainer: {
    backgroundColor: '#850C70E5',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 99,
    textAlign: 'center',
    animationName: [fadeIn],
    animationDuration: '0.5s',
  },
  mapSize: {
    width: 271,
    height: 260,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})
