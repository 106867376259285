import React from 'react'

function MapClose() {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" stroke="white" stroke-opacity="0.75"/>
            <rect x="3" y="3" width="42" height="42" rx="21" fill="white" fill-opacity="0.9"/>
            <path d="M26.4226 24.0009L32.4771 17.9447C32.6407 17.7867 32.7712 17.5977 32.8609 17.3887C32.9507 17.1798 32.998 16.955 32.9999 16.7276C33.0019 16.5002 32.9586 16.2747 32.8725 16.0642C32.7863 15.8537 32.6592 15.6625 32.4984 15.5016C32.3375 15.3408 32.1463 15.2137 31.9358 15.1275C31.7253 15.0414 31.4998 14.9981 31.2724 15.0001C31.045 15.002 30.8202 15.0493 30.6113 15.1391C30.4023 15.2288 30.2133 15.3593 30.0553 15.5229L23.9991 21.5774L17.9447 15.5229C17.7867 15.3593 17.5977 15.2288 17.3887 15.1391C17.1798 15.0493 16.955 15.002 16.7276 15.0001C16.5002 14.9981 16.2747 15.0414 16.0642 15.1275C15.8537 15.2137 15.6625 15.3408 15.5016 15.5016C15.3408 15.6625 15.2137 15.8537 15.1275 16.0642C15.0414 16.2747 14.9981 16.5002 15.0001 16.7276C15.002 16.955 15.0493 17.1798 15.1391 17.3887C15.2288 17.5977 15.3593 17.7867 15.5229 17.9447L21.5774 23.9991L15.5229 30.0553C15.3593 30.2133 15.2288 30.4023 15.1391 30.6113C15.0493 30.8202 15.002 31.045 15.0001 31.2724C14.9981 31.4998 15.0414 31.7253 15.1275 31.9358C15.2137 32.1463 15.3408 32.3375 15.5016 32.4984C15.6625 32.6592 15.8537 32.7863 16.0642 32.8725C16.2747 32.9586 16.5002 33.0019 16.7276 32.9999C16.955 32.998 17.1798 32.9507 17.3887 32.8609C17.5977 32.7712 17.7867 32.6407 17.9447 32.4771L23.9991 26.4226L30.0553 32.4771C30.2133 32.6407 30.4023 32.7712 30.6113 32.8609C30.8202 32.9507 31.045 32.998 31.2724 32.9999C31.4998 33.0019 31.7253 32.9586 31.9358 32.8725C32.1463 32.7863 32.3375 32.6592 32.4984 32.4984C32.6592 32.3375 32.7863 32.1463 32.8725 31.9358C32.9586 31.7253 33.0019 31.4998 32.9999 31.2724C32.998 31.045 32.9507 30.8202 32.8609 30.6113C32.7712 30.4023 32.6407 30.2133 32.4771 30.0553L26.4226 23.9991V24.0009Z" fill="#850C70"/>
        </svg>
    )
}

export default MapClose
