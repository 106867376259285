import { Html } from '@react-three/drei';
import { css, StyleSheet } from 'aphrodite';
import React, {useState, useEffect} from 'react'
import Lottie from 'react-lottie';
import animationData from '../assets/lottie/RotAnimLottie2.json'
import {useApartmentPreview, useTutorial} from '../../store'
import UserPromptContainer from '../assets/images/UserPromptContainer';
import UserPromptContainerFlipped from '../assets/images/UserPromptContainerFlipped';
import { isMobile } from 'react-device-detect';


function UserPrompts() {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      };

    const {showOnce, setShowOnce, setShowHint} = useTutorial()
    const [hintState, setHintState] = useState(false)
    const [playLottie, setPlayLottie] = useState(false)

    function Timer(){
        setTimeout(() => {
            setHintState(false)
            setShowHint(false)
        }, 5000);
    }

    useEffect(() => {
        if(showOnce){
            setHintState(true)
            setShowOnce(false)
            Timer();
        }
    }, [])

    useEffect(() => {
        const unsub = useTutorial.subscribe((paw, previousPaw) => {
            if(!hintState){
                setHintState(true);
                Timer()
            }
        }, state => state.showHint)

        return function cleaup(){
            unsub();
        }
    },[])

    useEffect(() => {
        const unsub1 = useApartmentPreview.subscribe((paw,previousPaw) => {setPlayLottie(paw)}, state => state.lottieState)

        return function cleanup(){
            unsub1()
        }
    })

    if(hintState){
        return (
            <>
            {/* {
                playLottie &&
                <div className={css(styles.dragContainer)}>
                    <div className={css(styles.lottieSize)}>
                        <Lottie options={defaultOptions}/>
                    </div> 
                </div>
            }
           
            <div className={css(styles.dragContainer)}>
                <div className={css(styles.lottieSize)}>
                </div> 
            </div>
            <div className={css(styles.userPromptContainer)}>
                <UserPromptContainer/>
                <div className={css(styles.TextContainer)}>
                    <div className={css(styles.dragText)}>Click and drag the screen to rotate the model</div>
                </div>
            </div>

            <div className={css(styles.teleportPromptContainer)}>
                {!isMobile ? <UserPromptContainer/> : <UserPromptContainerFlipped/>}
                <div className={css(styles.TextContainer1)}>
                    <div className={css(styles.dragText)}>Click the button to teleport into this room.</div>
                </div>
            </div> */}

            <div className={css(styles.automatedPromptContainer)}>
                {!isMobile ? <UserPromptContainer/> : <UserPromptContainerFlipped/> }
                <div className={css(styles.TextContainer2)}>
                    <div className={css(styles.dragText)}>Click to begin an automated walkthrough of the apartment.</div>
                </div>
            </div>
            </>
        )
    }
    else{
        return null;
    }
   
}

export default UserPrompts

const styles = StyleSheet.create({
    dragContainer:{
        position:'absolute',
        // top:420,
        left:650,
        '@media(min-width:556px) and (orientation:landscape)':{
            // top:194,
            // bottom: 33,
            top:'45vh',
            left: '35vw'
            // left: 310
        },
        '@media(min-width:992px) and (orientation:landscape)':{
            top:470,
        },
        '@media(min-width:1200px)':{
            // top:470,
            // left:650,
            top:'65vh',
            left:'40vw',
        },
        '@media(min-width:2560px)':{
            // top:470,
            // left:650,
            top:'70vh',
            left:'40vw',
        },
    },
    lottieSize:{
        width:200,
        height:100,
        '@media(min-width:556px) and (orientation:landscape)':{
            transform: 'scale(0.5)',
        },
        '@media(min-width:992px) and (orientation:landscape)':{
            transform: 'scale(1)',
        },
        '@media(min-width:1200px)':{
            transform: 'scale(1)',
        },
        '@media(min-width:1600px)':{
            transform: 'scale(1)',
        },
        '@media(min-width:1920px)':{
            transform: 'scale(1.3)',
        },
        '@media(min-width:2560px)':{
            transform: 'scale(2)',
        },
    },
    userPromptContainer:{
        position:'absolute',
        // top:170,
        bottom: 320,
        left:510,
        width:200,
        height:'auto',
        textAlign:'center',
        '@media(min-width:556px) and (orientation:landscape)':{
            transform: 'scale(0.7)',
            // bottom: 100,
            // left: 300
            bottom: '25vh',
            left: '50vw'
        },
        '@media(min-width:992px) and (orientation:landscape)':{
            transform: 'scale(1)',
            bottom: 263,
            left:770,
        },
        '@media(min-width:1200px)':{
            transform: 'scale(1)',
            // bottom: 263,
            // left:770,
            bottom: '35vh',
            left: '50vw'
        },
        '@media(min-width:1600px)':{
            transform: 'scale(1.3)',
            // bottom: 263,
            // left:770,
            bottom: '35vh',
            left: '50vw'
        },
        '@media(min-width:2560px)':{
            transform: 'scale(2)',
            // bottom: 263,
            // left:770,
            bottom: '35vh',
            left: '50vw'
        },
    },
    teleportPromptContainer:{
        position:'absolute',
        top:130,
        left:795,
        width:200,
        height:'auto',
        textAlign:'center',
        '@media(min-width:556px) and (orientation:landscape)':{
            // top: 50,
            // left: 90,
            top: '15vh',
            left: '18vw',
            transform: 'scale(0.7)'
        },
        '@media(min-width:992px) and (orientation:landscape)':{
            top:130,
            left:795,
            transform: 'scale(1)'
        },
        '@media(min-width:1200px)':{
            // top:130,
            // left:795,
            top: '20vh',
            left: '59vw',
            transform: 'scale(1)'
        },
        '@media(min-width:1600px)':{
            // top:130,
            // left:795,
            top: '20vh',
            left: '62vw',
            transform: 'scale(1.3)'
        },
        '@media(min-width:2560px)':{
            // top:130,
            // left:795,
            top: '20vh',
            left: '64vw',
            transform: 'scale(2)'
        },
    },
    automatedPromptContainer:{
        position:'absolute',
        // top:650,
        bottom: 117,
        left:880,
        width:200,
        height:'auto',
        textAlign:'center',
        '@media(min-width:556px) and (orientation:portrait)':{
            display: 'none'
        },
        '@media(min-width:556px) and (orientation:landscape)':{
            // bottom: 37,
            // left: 90,
            bottom: '10vh',
            left: '15vw',
            transform: 'scale(0.7)'
        },
        '@media(min-width:768px) and (orientation:landscape)':{
            // bottom: 37,
            // left: 90,
            bottom: '10vh',
            left: '20vw',
            transform: 'scale(0.7)'
        },
        '@media(min-width:992px) and (orientation:landscape)':{
            bottom: '15vh',
            left:'64vw',
            transform: 'scale(1.2)'
        },
        '@media(min-width:1200px)':{
            // bottom: 117,
            // left:880,
            bottom: '15vh',
            left:'60vw',
            transform: 'scale(1.5)'
        },
        '@media(min-width:1400px)':{
            // bottom: 117,
            // left:880,
            bottom: '15vh',
            left:'60vw',
            transform: 'scale(1)'
        },
        '@media(min-width:1600px)':{
            // bottom: 117,
            // left:880,
            bottom: '14vh',
            left:'61vw',
            transform: 'scale(1.4)'
        },
        '@media(min-width:1920px)':{
            // bottom: 117,
            // left:880,
            bottom: '12vh',
            left:'59vw',
            transform: 'scale(1.5)'
        },
        '@media(min-width:2560px)':{
            // bottom: 117,
            // left:880,
            bottom: '12vh',
            left:'61vw',
            transform: 'scale(2)'
        },
    },
    TextContainer:{
        textAlign:'center',
        position:'absolute',
        top:15,
        left:10,
        width:'85%',
        '@media(min-width:556px) and (orientation:landscape)':{
            left:20,
        },
        '@media(min-width:992px) and (orientation:landscape)':{
            left:20,
        },
    },
    TextContainer1:{
        textAlign:'center',
        position:'absolute',
        top:15,
        left:20,
        width:'85%',
        // '@media(min-width:556px) and (orientation:landscape)':{
        //     left:20,
        // },
        // '@media(min-width:1200px) and (orientation:landscape)':{
        //     top:130,
        //     left:795,
        // },
    },
    TextContainer2:{
        textAlign:'center',
        position:'absolute',
        top:8,
        left:20,
        width:'85%'
    },
    dragText:{
        fontFamily:'DIN-Regular',
        color:'#850C70',
        fontSize:15,
        lineHeight:'17px',
        textAlign:'left'
    }
})
