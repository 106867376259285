
import React, { useEffect, useState } from 'react';
import { useLocation } from 'wouter';
import { panoramas } from '../../constants';
import { useGuidedTour } from '../../store';

function GuidedTour() {

    let interval = null
    const [seconds, setSeconds] = useState(5)
    const [count, setCount] = useState(1)
    const [pause, setPause] = useState(false);
    const [location, setLocation] = useLocation()
    const [tour, setTour] = useState(false);
    const {setGuidedTour, setTourCompleted} = useGuidedTour();


    useEffect(() => {
        if(tour){
            interval = setInterval(() => {
                if(!pause) { 
                    if (seconds > 0) {
                        setSeconds(seconds - 1);
                    }
                    else{
                        if(count < panoramas.length){
                            setLocation(panoramas[count]);
                            setCount(count + 1);
                            setSeconds(5);
                        }
                        else{
                            clearInterval(interval);
                            setCount(1);
                            setSeconds(5);
                            setPause(false)
                            setGuidedTour(false)
                            setTourCompleted(true)
                        }
                    }
                }
                }, 1000);
        }
        else{
            clearInterval(interval);
            setCount(1);
            setSeconds(5);
            setPause(false)
            setGuidedTour(false)
        }

        return () => clearInterval(interval);
    }, [tour, seconds, count,pause])

    useEffect(() => {
        const unsub = useGuidedTour.subscribe((paw, previousPaw) => {TourState(paw)}, state =>state.guidedTour);
        const unsub1 = useGuidedTour.subscribe((paw, previousPaw) => {setPause(paw)}, state =>state.pauseTour);

        return function cleanup(){
            unsub();
            unsub1();
        }
    },[])

    function TourState(paw){
        if(paw){
            setTour(true);
            setLocation(panoramas[0])
        }
        else{
            clearInterval(interval);
            setCount(1);
            setSeconds(5);
            setTour(false)
            setPause(false)
        }
    }

    return null;
}

export default GuidedTour

