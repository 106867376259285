import { StyleSheet,css } from 'aphrodite'
import React, { useEffect, useState } from 'react'
import BGSplashMobile from '../assets/images/BGSplashMobile.webp'

export default function RotateScreen() {

    const [portrait, setPortrait] = useState(false)

    useEffect(() => {
        console.log("UseEffect Rotate Screen")
        reportWindowResize();
        window.addEventListener('resize', reportWindowResize)

        return function cleanup(){
            window.removeEventListener('resize', reportWindowResize)
        }
    },[])

    function reportWindowResize(){
        if(window.innerWidth < window.innerHeight){
            setPortrait(true)
        }
        else{
            setPortrait(false)
        }
    }

    if(portrait){
        return (
            <div className={css(styles.mobileContainer)}>
                <div className={css(styles.text)}>
                    Please rotate your device to play the experience. 
                </div>
            </div>
        )
    }
    else{
        return null;
    }
}

const styles = StyleSheet.create({
    mobileContainer:{
        zIndex:200,
        position:'absolute',
        backgroundImage: `url(${BGSplashMobile})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        textAlign:'center',
        top:0,
        right:0,
        bottom:0,
        left:0,
    },
    text:{
        position:'absolute',
        fontSize:16,
        lineHeight:'19px',
        color:'#E5BD79',
        bottom:150,
        width: 328,
        textAlign: 'center',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        '@media(min-width: 768px) and (orientation:portrait)': {
            fontSize: 20,
            lineHeight: '28px'
        },
    }

})


