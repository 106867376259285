import './App.css'
import { Canvas } from '@react-three/fiber'
import LoadingScreen from './components/core/LoadingScreen'
import { a as web } from '@react-spring/web'
import ApartmentPreviewBackground from './components/context/ui/ApartmentPreviewBackground'
import HUDManager from './components/core/HUDManager'
import LoadingManager from './components/core/LoadingManager'
import { CameraController } from './components/core/CameraController'
import { Suspense } from 'react'
import PanoramaManager from './components/core/PanoramaManager'
import { useEffect } from 'react'
// import { Leva } from 'leva'
// import Controllables from './components/helpers/Controllables'
// import CameraValues from './components/helpers/CameraValues'

function App() {
  useEffect(() => {
    console.log(window.innerHeight + 'Height')
    console.log(window.innerWidth + 'width')
  }, [])

  return (
    <web.main>
      {/* <Leva
        flat // default = false,  true removes border radius and shadow
        oneLineLabels // default = false, alternative layout for labels, with labels and fields on separate rows
        collapsed // default = false, when true the GUI is collpased
        hidden={false}
      /> */}
      <ApartmentPreviewBackground />
      <Canvas dpr={[1.5, 1.5]} colorManagement flat>
        {/* <color attach="background" args={'#850C70E5'} /> */}
        {/* <axesHelper args={[300, 300]} /> */}
        <ambientLight />
        <pointLight position={[10, 10, 10]} />
        <CameraController />
        <LoadingManager />
        {/* <Controllables/> */}
        <Suspense fallback={null}>
          <PanoramaManager />
        </Suspense>
      </Canvas>

      {/* <CameraValues/> */}
      <LoadingScreen />
      <HUDManager />
    </web.main>
  )
}

export default App
