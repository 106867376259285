import React, { useState, useEffect } from 'react'
import { Route } from 'wouter'
import { useEverythingLoaded } from '../../store'
import ApartmentPreviewUI from '../context/ApartmentPreviewUI'
import Congratulations from '../context/Congratulations'
import Guide from '../context/Guide'
import Menu from '../context/Menu'
import Branding from './Branding'

export default function HUDMAnager() {
  const [previewScreen, setPreviewScreen] = useState(false)

  useEffect(() => {
    const unsub = useEverythingLoaded.subscribe(
      (paw, previousPaw) => {
        setPreviewScreen(paw)
      },
      (state) => state.agreementAccepted
    )

    return function cleanup() {
      unsub()
    }
  }, [])

  return (
    <>
      <Branding />
      {previewScreen && (
        <>
          <Route path="/home">
            <ApartmentPreviewUI />
          </Route>
          <Menu />
        </>
      )}
      <Guide />
      <Congratulations />
    </>
  )
}
