import React, { useEffect, useState } from 'react'
import { useMaster } from '../../store'
import { css, StyleSheet } from 'aphrodite'
import MapMarker from '../assets/Hotspots/MapMarker'
import { useLocation } from 'wouter'
import { useSpring } from '@react-spring/core';
import {a as animated} from '@react-spring/web';
import MapImage from '../assets/images/MapImage.png'
import { isMobileOnly } from 'react-device-detect'

function MapComponent({showMap}) {
    const {getObject} = useMaster()
    const [location, setLocation] = useLocation()
    const [currentObject, setCurrentObject] = useState({markerPosition:{}})


    // function setObjectValue(){
    //     setCurrentObject(getObject(location))
    // }
    // useEffect(() => {
    //     setObjectValue()    
    // },[location])

    const fadeinAnim = useSpring({
        displayMap : showMap ? 1 : 0,
        opacity : showMap ? 1 : 0,
        config : {
            duration: 800
        }
    })
    // `translate(${x}px, ${y}px)`
    // getObject(location).mapMarkerPosition != null ? `translate(${getObject(location).mapMarkerPosition.right}px, ${getObject(location).mapMarkerPosition.bottom}px)` : `translate(0px, 0px)`
    
    const markerAnim = useSpring({
        opacity : 1,
        transform: getObject(location).mapMarkerPosition != null ? 
                   `translate(
                       ${isMobileOnly ?  getObject(location).mapMarkerPosition.mobileX : getObject(location).mapMarkerPosition.right}vw, 
                       ${isMobileOnly ? getObject(location).mapMarkerPosition.mobileY : getObject(location).mapMarkerPosition.bottom}vh )`:
                   `translate(0px, 0px)`,
        from: {
            opacity: 0
        },
        config : {
            duration: 800
        }
    })

    return (
        <>           
        <animated.div className={css(styles.mapContainer)} style={fadeinAnim}>
            <animated.div className={css(styles.mapMarker)} style={markerAnim}>
                <MapMarker markerAnim={markerAnim} />
            </animated.div>
        </animated.div>
        
        </>
    )
}

export default MapComponent

const styles = StyleSheet.create({
    mapContainer: {
        position:'absolute',
        bottom:117,
        right: 76,
        width: '30vw',
        height: '40vh',
        pointerEvents: 'none',
        backgroundImage: `url(${MapImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        // backgroundColor: 'red',
        backgroundPosition: 'center',
        
        '@media (max-width: 576px) and (orientation: landscape)':{
            bottom:38,
            right: 54,
            width: '45vw',
            height: '65vh'
        },
        '@media (min-width: 768px) and (orientation: landscape)':{
            bottom:38,
            right: 54,
            width: '45vw',
            height: '65vh'
        },
        '@media (min-width: 992px) and (orientation: landscape)':{
            bottom:56,
            right: 48,
            width: '30vw',
            height: '40vh',
        },
        '@media (min-width: 1400px)':{
            bottom:95,
            right: 76,
            width: '30vw',
            height: '40vh',

        },
        '@media (min-width: 2560px)':{
            bottom:115,
            right: 76,
        },
    },
    mapMarker: {
        float: 'right',
        height: 'auto',
        width: '1.5vw',
        '@media (max-width: 576px) and (orientation: landscape)':{
            width: '2.5vw',
        },
        '@media (min-width: 768px) and (orientation: landscape)':{
            width: '2.5vw',
        },
        '@media (min-width: 992px) and (orientation: landscape)':{
            width: '2vw',
        },
        '@media (min-width: 1200px)':{
            width: '2vw',
        },
        '@media (min-width: 1400px)':{
            width: '1.5vw',
        },
        '@media (min-width: 2560px)':{
            width: '1.5vw',
        },
    },
})