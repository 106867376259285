import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useLocation } from 'wouter'
import { useApartmentPreview, useGuidedTour, useTutorial1 } from '../../store'
import InfoHS from '../assets/Hotspots/InfoHS'
import Arrow from '../assets/Hotspots/Arrow'
import FreeRoam from '../assets/Hotspots/FreeRoam'
import ViewHS from '../assets/Hotspots/ViewHS'
import InfoHotspotCross from '../assets/Hotspots/InfoHotspotCross'
import UserPromptContainer from '../assets/images/UserPromptContainer'
import { isMobile } from 'react-device-detect'

export default function HotspotSpawn(props) {
  const [location, setLocation] = useLocation()
  const { setApartmentPreview } = useApartmentPreview()
  const { guidedTour, setPauseTour, infoPopup, setInfoPopup } = useGuidedTour()
  const [count, setCount] = useState(0)
  const [infoState, setInfoState] = useState(false)

  useEffect(() => {}, [guidedTour])

  useEffect(() => {
    setCount(useTutorial1.getState().count)
    const unsub = useTutorial1.subscribe(
      (paw, previousPaw) => {
        setCount(paw)
      },
      (state) => state.count
    )

    const unsub1 = useGuidedTour.subscribe(
      (paw, previousPaw) => {
        setInfoState(paw)
      },
      (state) => state.infoPopup
    )

    return function cleanup() {
      unsub()
      unsub1()
    }
  }, [])

  function HandleClick(type) {
    console.log(type)
    switch (type) {
      case 'apartmentPreview':
        setApartmentPreview(false)
        setLocation(props.href)
        break
      case 'FreeRoam':
        setLocation(props.href)
        console.log('FreeRoam')
        break
      case 'View':
        break
      case 'Info':
        setInfoPopup(!infoPopup)
        if (!infoState) {
          if (guidedTour) setPauseTour(true)
        } else {
          if (guidedTour) setPauseTour(false)
        }
        break
      default:
        break
    }
  }

  return (
    <>
      {props.object.type === 'apartmentPreview' && (
        <div>
          <button
            className={css(styles.hospotButton, styles.scaleHS)}
            onClick={() => HandleClick(props.object.type)}
          >
            {props.svg}
          </button>
        </div>
      )}
      {props.object.type === 'FreeRoam' && !guidedTour && (
        <div>
          <button
            className={css(styles.hospotButton, styles.scaleHS)}
            onClick={() => HandleClick(props.object.type)}
          >
            <FreeRoam />
          </button>
        </div>
      )}
      {props.object.type === 'Arrow' && guidedTour && (
        <div>
          <button
            className={css(styles.hospotButton, styles.scaleHS)}
            onClick={() => HandleClick(props.object.type)}
          >
            <Arrow />
          </button>
        </div>
      )}
      {props.object.type === 'Info' && (
        <>
          <button
            className={css(styles.hospotButton, styles.scaleHS)}
            style={{ width: props.object.width, height: props.object.height }}
            onClick={() => HandleClick(props.object.type)}
          >
            {infoState && <InfoHotspotCross />}
            {!infoState && <InfoHS />}
          </button>
          {infoState && (
            <div className={css(styles.userPromptContainer1)}>
              <div className={css(styles.userPromptContainer3)} />
              <div className={css(styles.TextContainerInfo)}>
                <div className={css(styles.dragText1)}>
                  LIVING + DINING
                  <br />
                  21’8” X 10’0”
                </div>
              </div>
            </div>
          )}
          {count === 1 && !infoState && (
            <div className={css(styles.userPromptContainer1)}>
              <UserPromptContainer />
              <div className={css(styles.TextContainer1)}>
                <div className={css(styles.dragText)}>
                  {!isMobile
                    ? 'Click to know more about this apartment.'
                    : 'Tap to know more about this apartment.'}
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {props.object.type === 'View' && (
        <>
          <button
            className={css(styles.hospotButton, styles.scaleHS)}
            style={{ width: props.object.width, height: props.object.height }}
            onClick={() => HandleClick(props.object.type)}
          >
            <ViewHS />
          </button>
          {count === 3 && (
            <div className={css(styles.userPromptContainer1)}>
              <UserPromptContainer />
              <div className={css(styles.TextContainer1)}>
                <div className={css(styles.dragText)}>
                  {!isMobile
                    ? 'Click to view the balcony area.'
                    : 'Tap to view the balcony area'}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  hospotButton: {
    outline: 0,
    background: 'none',
    border: 'none',
    padding: 0,
    ':hover': {
      cursor: 'pointer',
    },
  },
  scaleHS: {
    transform: 'scale(0.3)',
    // Small devices (landscape phones, 576px and up)
    '@media (min-width: 556.98px) and (orientation: landscape)': {
      transform: 'scale(0.5)',
    },
    // Medium devices (tablets, 768px and up)
    '@media (min-width: 768px) and (orientation: landscape)': {
      transform: 'scale(0.5)',
    },
    // Large devices (desktops, 992px and up)
    '@media (min-width: 992px)': {
      transform: 'Scale(1)',
    },
    // X-Large devices (large desktops, 1200px and up)
    '@media (min-width: 1200px)': {
      transform: 'Scale(1.5)',
    },
    // XX-Large devices (larger desktops, 1400px and up)
    '@media (min-width: 1400px)': {
      transform: 'Scale(1)',
    },
    '@media (min-width: 1920px)': {
      transform: 'Scale(1.5)',
    },
    '@media (min-width: 2560px)': {
      transform: 'Scale(2)',
    },
  },
  scaling: {
    transform: 'Scale(1,1)',
    // Small devices (landscape phones, 576px and up)
    '@media (min-width: 575.98px)': {
      transform: 'Scale(1,1)',
    },
    // Medium devices (tablets, 768px and up)
    '@media (min-width: 768px)': {
      transform: 'Scale(1,1)',
    },
    // Large devices (desktops, 992px and up)
    '@media (min-width: 992px)': {
      transform: 'Scale(1.5,1.5)',
    },
    // X-Large devices (large desktops, 1200px and up)
    '@media (min-width: 1200px)': {
      transform: 'Scale(1.5,1.5)',
    },
    // XX-Large devices (larger desktops, 1400px and up)
    '@media (min-width: 1400px)': {
      transform: 'Scale(2,2)',
    },
  },
  none: {
    transform: 'Scale(0,0)',
  },

  TextContainerInfo: {
    position: 'absolute',
    top: 20,
    left: '7.5%',
    width: '85%',
    textAlign: 'center',
    '@media (min-width: 556.98px)': {
      top: 10,
    },
    '@media (min-width: 992.98px)': {
      top: 20,
    },
    '@media (min-width: 1200px)': {
      top: 20,
    },
    '@media (min-width: 1920px)': {
      left: 0,
      top: 18,
    },
    '@media (min-width: 2560px)': {
      left: -15,
      top: 20,
    },
  },
  dragText: {
    fontFamily: 'DIN-Regular',
    color: '#850C70',
    fontSize: 18,
    lineHeight: '21px',
    textAlign: 'left',
    '@media (min-width: 556.98px) and (orientation: landscape)': {
      fontSize: 10,
      lineHeight: '12.06px',
    },
    '@media (min-width: 992px)': {
      fontSize: 18,
      lineHeight: '21px',
    },
    '@media (min-width: 1920px)': {
      fontSize: 22,
      lineHeight: '25px',
    },
    '@media (min-width: 2560px)': {
      fontSize: 32,
      lineHeight: '36px',
    },
  },
  dragText1: {
    fontFamily: 'DIN-Regular',
    color: '#850C70',
    fontSize: 18,
    lineHeight: '21px',
    // Small devices (landscape phones, 576px and up)
    '@media (min-width: 556.98px) and (orientation: landscape)': {
      fontSize: 12,
      lineHeight: '14.06px',
    },
    // Medium devices (tablets, 768px and up)
    '@media (min-width: 768px) and (orientation: landscape)': {
      fontSize: 12,
      lineHeight: '14.06px',
    },
    // Large devices (desktops, 992px and up)
    '@media (min-width: 992px) and (orientation: landscape)': {
      fontSize: 18,
      lineHeight: '21px',
    },
    // X-Large devices (large desktops, 1200px and up)
    '@media (min-width: 1200px)': {
      fontSize: 18,
      lineHeight: '21px',
    },
    // XX-Large devices (larger desktops, 1400px and up)
    '@media (min-width: 1400px)': {
      fontSize: 18,
      lineHeight: '21px',
    },
    '@media (min-width: 1920px)': {
      fontSize: 22,
      lineHeight: '25px',
    },
    '@media (min-width: 2560px)': {
      fontSize: 32,
      lineHeight: '36px',
    },
  },
  userPromptContainer1: {
    textAlign: 'center',
    left: 74,
    top: -87,
    width: 242,
    height: 'auto',
    position: 'absolute',
    '@media (min-width: 556.98px) and (orientation: landscape)': {
      width: 136,
      height: 'auto',
      left: 64,
      top: -22,
      // transform: 'scale(0.8)'
    },
    '@media (min-width: 992px)': {
      width: 242,
      height: 'auto',
      left: 74,
      top: -87,
    },
    '@media (min-width: 1200px)': {
      width: 242,
      height: 'auto',
      left: 74,
      top: -87,
    },
    '@media (min-width: 1920px)': {
      width: 292,
      height: 'auto',
      left: 94,
      top: -87,
    },
    '@media (min-width: 2560px)': {
      width: 442,
      height: 'auto',
      left: 124,
      top: -117,
    },
  },
  userPromptContainer2: {
    textAlign: 'center',
    width: 242,
    height: 82,
    marginLeft: 60,
    marginTop: -180,
    position: 'relative',
  },
  userPromptContainer3: {
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    textAlign: 'center',
    width: 242,
    height: 82,
    '@media (min-width: 556.98px) and (orientation: landscape)': {
      width: 136,
      height: 48,
    },
    '@media (min-width: 992px)': {
      width: 242,
      height: 82,
    },
    '@media (min-width: 1200px)': {
      width: 242,
      height: 82,
    },
    '@media (min-width: 2560px)': {
      width: 342,
      height: 112,
      borderRadius: '12px',
    },
  },
  TextContainer1: {
    position: 'absolute',
    top: 15,
    left: 25,
    width: '85%',
    textAlign: 'center',
    '@media (min-width: 556.98px) and (orientation: landscape)': {
      top: 10,
      left: 15,
    },
    '@media (min-width: 992px)': {
      top: 15,
      left: 25,
    },
    '@media (min-width: 1920px)': {
      top: 20,
      left: 30,
    },
    '@media (min-width: 2560px)': {
      top: 40,
      left: 50,
    },
  },
  FreeRoamContainer: {
    position: 'absolute',
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    textAlign: 'center',
    height: '100%',
    width: 242,
    height: 82,
  },
  mainContainer: {
    backgroundColor: 'red',
    width: 'content-fit',
  },
})
