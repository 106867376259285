import React,{useEffect, useState} from 'react';
import { StyleSheet, css } from 'aphrodite';
import {animated, useSpring} from '@react-spring/web'
import BirlaTisyaPS from '../assets/logo/BirlaTisyaPS.webp'
import BirlaTisyaLogoPink from '../assets/logo/BirlaTisyaLogoPink.webp'
import {useEverythingLoaded, useGuidedTour, useMenuButtons } from '../../store';
import MapImage from '../assets/images/MapImage.png';
import { useLocation } from 'wouter';
import { fadeIn } from 'react-animations';

export default function Branding(props){

    const [componentVisibility, setComponentVisibility] = useState(false);
    const [location, setLocation] = useLocation()
    const {showGuide} = useMenuButtons()
    const {tourCompleted} = useGuidedTour()

    const opacity = useSpring({
        opacity : componentVisibility ? 1 : 0
    })

    useEffect(() => {
        const unsub = useEverythingLoaded.subscribe((paw, previousPaw) => {setComponentVisibility(paw); console.log("subscription" + paw)}, state => state.agreementAccepted)

        return function cleanup(){
            unsub();
        }
    }, [])

    useEffect(() => {},[showGuide, tourCompleted, componentVisibility])

    return(
        <animated.div className={css(styles.LogoContainer)} style={opacity}>
            {
                location !== '/' && location !== '/home' && !showGuide && !tourCompleted &&
                <img src={BirlaTisyaLogoPink} alt={'BirlaTisya'} className={css(styles.dimensions)}/>
            }
            {
                (location === '/' || location === '/home' || showGuide || tourCompleted) &&
                <img src={BirlaTisyaPS} alt={'BirlaTisya'} className={css(styles.dimensions)}/>
            }
            
        </animated.div>
    )
}

const styles = StyleSheet.create({
    LogoContainer:{
        position:'absolute',
        zIndex:11,
        top:18,
        left:14.5,
        // Small devices (landscape phones, 576px and up)
        '@media(min-width:556px) and (orientation:landscape)': {
            top:5,
            left:14.5,
        },
        // Medium devices (tablets, 768px and up)
        '@media (min-width: 768px) and (orientation:landscape)' :{
            top:5,
            left:14.5,
        },
        // Large devices (desktops, 992px and up)
        '@media(min-width: 992px) and (orientation:landscape)':{
            top:18,
            left:14.5,
        },
        // X-Large devices (large desktops, 1200px and up)
        '@media (min-width: 1200px)': {
            top:18,
            left:14.5,
        },
        // XX-Large devices (larger desktops, 1400px and up)
        '@media (min-width: 1400px)':{
            top:13.4,
            left:14.5,
        },
        '@media (min-width: 1600px)':{
            top:18,
            left:14.5,
        },
        '@media (min-width: 2560px)':{
            top:18,
            left:34.5,
        }
    },
    dimensions:{
        width:161,
        height:'auto',
      // Small devices (landscape phones, 576px and up)
        '@media(min-width:556px) and (orientation:landscape)': {
            width:68,
        },
        // Medium devices (tablets, 768px and up)
        '@media (min-width: 768px) and (orientation:landscape)' :{
            width:68,
        },
        // Large devices (desktops, 992px and up)
        '@media(min-width: 992px) and (orientation:landscape)':{
            width:111,
        },
        // X-Large devices (large desktops, 1200px and up)
        '@media (min-width: 1200px)': {
            width:161,
        },
        // XX-Large devices (larger desktops, 1400px and up)
        '@media (min-width: 1400px)':{
            width:161,
        },
        '@media (min-width: 1600px)':{
            width:161,   
        },
        '@media(min-width: 2560px)': {
            width:201, 
        },
    }
})