import React from 'react'
import { Html } from '@react-three/drei'
import { useState, useEffect } from 'react'
import { useGuidedTour, useTutorial1 } from '../../store'
import { css, StyleSheet } from 'aphrodite'
import { fadeIn, fadeOut } from 'react-animations'
import UserPromptContainer from '../assets/images/UserPromptContainer'
import { a as three } from '@react-spring/three'
import { useSpring } from '@react-spring/core'

export default function Hotspot(props) {
  const [FreeroamPromptState, setFreeRoamPromptState] = useState(false)

  useEffect(() => {}, [props.hidden])

  useEffect(() => {
    const unsub = useTutorial1.subscribe(
      (paw, previousPaw) => {
        if (paw === 2) {
          setFreeRoamPromptState(true)
        } else {
          setFreeRoamPromptState(false)
        }
      },
      (state) => state.count
    )

    return function cleanup() {
      unsub()
    }
  }, [])

  const anim = useSpring({
    scale: FreeroamPromptState ? [1, 1, 1] : [0, 0, 0],
  })

  return (
    <>
      <group
        position={props.position}
        scale={props.scale}
        rotation={props.rotation}
      >
        {(props.type === 'Info' ||
          props.type === 'apartmentPreview' ||
          props.type === 'View') && (
          <Html
            center
            className={css(props.hidden ? styles.fadeIn : styles.fadeOut)}
          >
            {props.children}
          </Html>
        )}
        {props.type === 'FreeRoam' && (
          <Html
            transform
            center
            className={css(props.hidden ? styles.fadeIn : styles.fadeOut)}
          >
            {props.children}
          </Html>
        )}
        {props.type === 'FreeRoam' && !useGuidedTour.getState().guidedTour && (
          <three.group scale={anim.scale}>
            <Html transform className={css(styles.displayNone)}>
              {props.children}
            </Html>
            <Html
              className={css(
                props.hidden && FreeroamPromptState
                  ? styles.fadeIn
                  : styles.fadeOut
              )}
            >
              <div className={css(styles.FreeRoamContainer)}>
                <div className={css(styles.promptContainer)}>
                  <UserPromptContainer />
                </div>
                <div className={css(styles.TextContainer1)}>
                  <div className={css(styles.dragText)}>
                    Click to teleport to this point.
                  </div>
                </div>
              </div>
            </Html>
          </three.group>
        )}
        {props.type === 'Arrow' && (
          <Html
            transform
            className={css(props.hidden ? styles.fadeIn : styles.fadeOut)}
          >
            {props.children}
          </Html>
        )}
      </group>
    </>
  )
}

const styles = StyleSheet.create({
  dragText: {
    fontFamily: 'DIN-Regular',
    color: '#850C70',
    fontSize: 18,
    lineHeight: '21px',
    textAlign: 'left',
  },
  // TextContainer1:{
  //     marginLeft:'auto',
  //     marginRight:'auto',
  //     textAlign:'center',
  //     width:'90%',
  //     paddingTop:18
  // },
  FreeRoamContainer: {
    position: 'relative',
    textAlign: 'center',
    width: 242,
    height: 82,
    marginLeft: 40,
    marginTop: -130,
    //   transformOrigin:'0 160%',
    //   transform:'scale(7)'
    '@media (min-width: 556.98px) and (orientation: landscape)': {
      transform: 'scale(0.6)',
      marginLeft: -36,
      marginTop: -86,
    },
    '@media (min-width: 768px) and (orientation: landscape)': {
      transform: 'scale(0.6)',
      marginLeft: -36,
      marginTop: -86,
    },
    '@media (min-width: 992.98px)': {
      transform: 'scale(1)',
    },
    '@media (min-width: 1200px)': {
      transform: 'scale(1)',
    },
    '@media (min-width: 1400px)': {
      transform: 'scale(1)',
      marginLeft: -10,
      marginTop: -110,
    },
    '@media (min-width: 1920px)': {
      transform: 'scale(1.5)',
      marginTop: -220,
    },
    '@media (min-width: 2560px)': {
      transform: 'scale(2)',
      marginTop: -220,
      marginLeft: 130,
    },
  },
  TextContainer1: {
    height: '100%',
    position: 'absolute',
    top: 15,
    left: 25,
    width: '85%',
    textAlign: 'center',
  },
  promptContainer: {
    width: '100%',
    height: '100%',
  },
  fadeOut: {
    opacity: 0,
    pointerEvents: 'none',
  },
  fadeIn: {
    animationName: [fadeIn],
    animationDuration: '0.6s',
    pointerEvents: 'auto',
  },
  showPrompts: {
    display: 'block',
    pointerEvents: 'auto',
  },
  displayNone: {
    display: 'none',
  },
})
