import React from 'react';
import {css,StyleSheet} from 'aphrodite';
import{fadeIn, fadeOut} from 'react-animations';
import { useLoader, usePanoramaViewer, useHotspotState, useEverythingLoaded } from '../../store';
import LoadingScreenBranding from './LoadingScreenBranding';
import { loadingItems, LoadingScreenText } from '../../constants';
import BGSplash from '../assets/images/BGSplash.webp'
import BGSplashMobile from '../assets/images/BGSplashMobile.webp'
// import BGSplashMobile from '../assets/images/BGSplashMobile.webp'

export default class SplashScreen extends React.Component{

    constructor(props){
        super(props);
        this.intervalVar = null
        this.state = {
            TotalLoaders: loadingItems.concat(usePanoramaViewer.getState().allLocations),
            DoneLoaders:[],
            LoadersDoneNumber:0,
            DonePercentage:0,
            DisplayPercentage:0,
            isDone:false,
            Effect:null,
            dataState: [],
            componentVisibility:true,
            fadeOut:false,
            totalPercentage:100,
            portrait:false,
            showPercentage: 1
        }
        this.lastTime = Date.now();
    }

    ReportLoaderDone(name){
        if(this.state.TotalLoaders.includes(name)){
            this.state.DoneLoaders.push(name);
            var done = this.state.LoadersDoneNumber;
            done++;
            var dp = (done/(this.state.TotalLoaders.length))*100;
            this.setState({
                LoadersDoneNumber:done,
                DonePercentage:dp
            })
        }
        else{
            
        }
    }

    _LoaderLoop = () =>{
        this.intervalVar = window.setInterval(()=>{
            var pp = this.state.DisplayPercentage;
            if(pp === 100){
                clearInterval(this.intervalVar);
                if(!this.state.portrait){
                    this.Load()
                }
                if(this.state.portrait){
                        this.setState({isDone:true});
                }
            }
            if(pp < this.state.DonePercentage && pp < 100){
                this.setState({
                    DisplayPercentage: pp+1,
                    showPercentage: 1 - ((pp+1)/100) 
                })
            }
        },10)
    }

    reportWindowSize = () => {
        if(window.innerWidth < window.innerHeight){
            this.setState({
                ...this.state,
                portrait:true
            })
            if(useEverythingLoaded.getState().agreementAccepted){
                useHotspotState.setState({ hotspotVisibility : false})
            }
        }
        else{
            this.setState({
                ...this.state,
                portrait:false
            })
            if(this.state.DisplayPercentage === 100){
                this.Load();
            }
            if(useEverythingLoaded.getState().agreementAccepted){
                useHotspotState.setState({ hotspotVisibility : true})
            }
        }
    }

    componentDidMount(){
        this.reportWindowSize();
        window.addEventListener('resize', this.reportWindowSize);
        this.ReportLoaderDone('./360renders/Daylight/2BHKAPassage1DL.webp')
        this._LoaderLoop();
        this.unsub = usePanoramaViewer.subscribe((paw,previouspaw) => {
            this.ReportLoaderDone(paw)
         }, (state) => state.currentLocation);
         this.unsub1 = useLoader.subscribe((paw, previouspaw) => {
            this.ReportLoaderDone(paw)
        }, (state) => state.loadedModel);
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.reportWindowSize)
        this.unsub()
        this.unsub1()
    }

    Load = () => {
        this.setState({isDone:true});
        setTimeout(() => {
            this.props.loadingComplete()
        }, 5000);
        setTimeout(() => {
            this.setState({fadeOut:true});
        }, 6000);
    }

    componentDidUpdate(){
        // if(!this.state.portrait){
        //     console.log("statement")
        //     this.Load()
        // }
    }

    AnimationEnded = () => {
        if(this.state.fadeOut){
            this.setState({
                ...this.state,
                componentVisibility:false
            })
        }
       
    }

    //this.state.isDone = Boolean Variable for all the model Loaded
    //this.state.DisplayPercentage = Percentage Value for the number of models loaded
    // style={{width: `${this.state.DisplayPercentage}%`}}
    render(){
            return (
                    <div className={css(this.state.componentVisibility ? this.state.portrait ? styles.mobileContainer : styles.container : styles.displayNone, this.state.fadeOut ? styles.FadeOut : null)} onAnimationEnd={this.AnimationEnded} >
                             <LoadingScreenBranding loadingCompleted = {this.state.isDone} portrait = {this.state.portrait} />
                            {
                                this.state.isDone && !this.state.portrait &&
                                <div className={ this.state.isDone ? css(styles.FadeIn) : null}>
                                    <div className={css(styles.welcomeText)}> {LoadingScreenText.splashScreenText }</div>
                                    <div className={css(styles.tisyaText)}> {LoadingScreenText.splashScreenTisya} </div>
                                </div>
                            }
                             <div className={css(this.state.isDone ? styles.displayNone : styles.ProgressBarContainer)}>
                                     <div className={css(this.state.isDone ? styles.displayNone : null, styles.progressbar)} style={{width : '100%', transform: `scaleX(${this.state.showPercentage})`}}></div>
                                     <div className={css(styles.LoadingText)}>
                                         LOADING
                                     </div>
                             </div>
                    </div>
            );
        }
    }

const styles = StyleSheet.create({
    container:{
        zIndex:199,
        position:'absolute',
        backgroundImage: `url(${BGSplash})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        textAlign:'center',
        top:0,
        right:0,
        bottom:0,
        left:0
    },
    mobileContainer:{
        position:'absolute',
        textAlign:'center',
        top:0,
        right:0,
        bottom:0,
        left:0,
        zIndex:201,
    },
    welcomeText:{
        color:'#E5BD79',
        marginTop: 80,
        marginLeft:'auto',
        marginRight:'auto',
        fontFamily : 'DIN-Regular',
        '@media(min-width:556px) ':{
            marginTop: 23,
            fontSize: 16,
            lineHeight: '19px',
            width: 256,
            // backgroundColor: 'red'
        },
        '@media(min-width: 768px) ': {
            marginTop: 23,
            fontSize: 16,
            lineHeight: '19px',
            width: 256,
        },
        '@media(min-width: 992px)': {
            marginTop: 40,
            fontSize: 20,
            lineHeight: '26px',
            width: 306,
        },
        '@media(min-width: 1200px)': {
            marginTop: 80,
            fontSize:38,
            lineHeight:'45px',
            width:600
        },
        '@media(min-width: 1400px)': {
            marginTop:53,
            fontSize:38,
            lineHeight:'45px',
            width:600
        },
        '@media(min-width: 1600px)': {
            marginTop: 80,
            fontSize:40,
            lineHeight:'47px',
            width:600
        },
        '@media(min-width:2560px)':{
            marginTop:80,
            fontSize:56,
            lineHeight:'68px',
            width:900
        }
    },
    tisyaText:{
        color: '#E5BD79',
        marginLeft:'auto',
        marginRight:'auto',
        fontFamily : 'DIN-Bold',
        '@media(min-width:556px) ':{
            fontSize: 32,
            lineHeight: '38px'
        },
        '@media(min-width: 768px) ': {
            fontSize: 32,
            lineHeight: '38px'
        },
        '@media(min-width: 992px) ': {
            fontSize: 32,
            lineHeight: '38px'
        },
        '@media(min-width: 1200px)': {
            marginTop: 10,
            fontSize:38,
            lineHeight:'40px',
            width:600
        },
        '@media(min-width: 1400px)': {
            marginTop: 10,
            fontSize:38,
            lineHeight:'45px',
            width:600
        },
        '@media(min-width: 1600px)': {
            marginTop:10,
            fontSize:40,
            lineHeight:'48px',
            width:600
        },
        '@media(min-width: 2560px)' :{
            marginTop:10,
            fontSize:64,
            lineHeight:'64px',
            width:600
        }
    },
    ProgressBarContainer:{
        backgroundColor:'none',
        width:110,
        marginRight:'auto',
        marginLeft:'auto',
        marginTop:50,
        height: 1,
        '@media(max-width:556px) and (orientation:portrait) ':{
            width:192,
            marginTop:10,
        },
        '@media(min-width:556px) and (orientation:landscape)':{
            width:192,
            marginTop:10,
            height: 1,
        },
        '@media(min-width: 768px) and (orientation:portrait)': {
            width:192,
            marginTop:10,
        },
        '@media(min-width: 768px) and (orientation:landscape)': {
            width:192,
            marginTop:10,
        },
        '@media(min-width: 992px) and (orientation:portrait)': {
           
        },
        '@media(min-width: 992px) and (orientation:landscape)': {
           
        },
        '@media(min-width: 1200px)': {
            width:400,
            marginTop:30,
            height: 1,
        },
        '@media(min-width: 1400px)': {
            width:400,
            marginTop:30,
            height: 2,
        },
        '@media(min-width: 1600px)': {
        },
        '@media(min-width:2560px)':{
            height: 4,
        }
    },
    progressbar: {
        height: '100%',
        backgroundColor: '#E5BD79',
        zIndex: 100,
    },
    LoadingText:{
        marginTop:10,
        fontSize:18,
        color:'#E5BD79',
        '@media(max-width:556px) and (orientation:portrait) ':{
            fontSize:16,
            lineHeight: '19px'
        },
        '@media(min-width:556px) and (orientation:portrait) ':{
            fontSize:16,
            lineHeight: '19px'
        },
        '@media(min-width:556px) and (orientation:landscape)':{
            fontSize:16,
            lineHeight: '19px'
        },
        '@media(min-width: 768px) and (orientation:portrait)': {
            marginTop:15,
            fontSize:20,
            lineHeight: '24px'
        },
        '@media(min-width: 768px) and (orientation:landscape)': {
            fontSize:16,
            lineHeight: '19px'
        },
        '@media(min-width: 992px) and (orientation:portrait)': {
            fontSize:20,
            lineHeight: '26px'
        },
        '@media(min-width: 992px) and (orientation:landscape)': {
            fontSize:20,
            lineHeight: '26px'
        },
        '@media(min-width: 1200px)': {
            marginTop:5,
            fontSize:20,
        },
        '@media(min-width: 1400px)': {
            marginTop:5,
            fontSize:20,
        },
        '@media(min-width: 1600px)': {
            marginTop:5,
            fontSize:20,
        },
        '@media (min-width: 1920px)': {
            fontSize:24,
        },
        '@media(min-width:2560px)':{
            marginTop:16,
            fontSize:32,

        }
    },
    FadeIn:{
        animationName: fadeIn,
        animationDuration: '3s',
    },
    FadeOut:{
        animationName: fadeOut,
        animationDuration: '1s',
        pointerEvents:'none'
    },
    displayNone:{
        display:'none',
        pointerEvents: 'none'
    },

})