import React, { useState ,useEffect} from 'react'
import Tween from './Tween';

export function TweenManager(props) {

  const [inputState, setInputState] = useState(true);


  const onTweenStartHelperFunctions = ()=>{
    setInputState(false)
  }

  const onTweenFinishHelperFunctions = ()=>{
    setInputState(true)
  }

  const onTweenFinish = (name) =>{
    onTweenFinishHelperFunctions()
  }

  const onTweenStart = (name) =>{
    onTweenStartHelperFunctions()
  }

  useEffect(() => {
    props.controls.current.enabled = inputState;
  }, [inputState])



    return (
      <>
      {
        props.tweenPosition != null &&
        <group>
        <Tween 
          tweenPoint = {props.tweenPosition} 
          onTweenFinish={onTweenFinish} 
          onTweenStart = {onTweenStart}
        />
      </group>
      }
    </>
  )
}


