import { useSpring } from '@react-spring/core'
import { css, StyleSheet } from 'aphrodite'
import React, { useState, useEffect } from 'react'
import { fadeIn } from 'react-animations'
import { useApartmentPreview } from '../../../store'
import BGPreview from '../../assets/images/BGPreview.webp'
import { animated as a } from '@react-spring/web'

export default function ApartmentPreviewBackground() {
  const [state, setState] = useState({
    backgroundVisibility: false,
  })
  const [fadeOut, setFadeOut] = useState(false)
  const anim = useSpring({
    opacity: fadeOut ? 0 : 1,
    delay: fadeOut ? 2000 : 0,
    onRest: () => {
      screenFadeOut()
    },
  })

  useEffect(() => {
    const unsub = useApartmentPreview.subscribe(
      (paw, previouspaw) => {
        if (paw) {
          setState({ backgroundVisibility: paw })
          setFadeOut(false)
        } else {
          setFadeOut(true)
        }
      },
      (state) => state.apartmentPreview
    )

    return function cleanup() {
      unsub()
    }
  }, [])

  function screenFadeOut() {
    if (fadeOut) {
      setState({ backgroundVisibility: false })
    }
  }

  return (
    <a.div
      style={anim}
      className={css(
        state.backgroundVisibility
          ? styles.backgroundContainer
          : styles.displayNone
      )}
    ></a.div>
  )
}

const styles = StyleSheet.create({
  backgroundContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'block',
    backgroundImage: `url(${BGPreview})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    textAlign: 'center',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  displayNone: {
    display: 'none',
  },
  screenFadeIn: {
    animationName: [fadeIn],
    animationDuration: '0.5s',
  },
  screenFadeOut: {
    animationName: {
      from: { opacity: 1 },
      to: { opacity: 0 },
    },
    '-moz-animation': {
      from: { opacity: 1 },
      to: { opacity: 0 },
    } /* Firefox */,
    '-webkit-animation': {
      from: { opacity: 1 },
      to: { opacity: 0 },
    } /* Safari and Chrome */,
    '-o-animation': {
      from: { opacity: 1 },
      to: { opacity: 0 },
    } /* Opera */,

    animationFillMode: 'forwards',
    '-webkit-animation-fill-mode': 'forwards',
    animationDuration: '1s',
    '-moz-animation-duration': '1s' /* Firefox */,
    '-webkit-animation-duration': '1s' /* Safari and Chrome */,
    '-o-animation-duration': '1s' /* Opera */,
    animationDelay: '3s',
    '-webkit-animation-delay': '3s' /* Safari and Chrome */,
  },
})
