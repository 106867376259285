/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef, useState } from 'react'
import { useGLTF } from '@react-three/drei'
import { a as three } from '@react-spring/three'
import { useSpring } from '@react-spring/core'
import { useApartmentPreview, useHotspotState, useLoader } from '../../store'
import HotspotManager from '../core/HotspotManager'
import { useLocation } from 'wouter'
import { isMobile } from 'react-device-detect'

export default function ApartmentPreview(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/glb/dollhouse/2BHKAPreview.glb')

  const [state, setState] = useState({
    apartmentVisibility: false,
    showHotspots: false,
  })

  const { setLottieState } = useApartmentPreview()
  const apartmentAnim = useSpring({
    scale: props.previewScreenVisibility
      ? isMobile
        ? [1.5, 1.5, 1.5]
        : [1, 1, 1]
      : [0, 0, 0],
    onRest: () => {
      if (props.previewScreenVisibility) {
        setLottieState(true)
      }
    },
  })
  const { setLoadedModel } = useLoader()
  const [location, setLocation] = useLocation()

  useEffect(() => {
    const unsub = useHotspotState.subscribe(
      (paw, previousPaw) => {
        setState({ ...state, showHotspots: paw })
      },
      (state) => state.hotspotVisibility
    )

    return function cleanup() {
      unsub()
    }
  }, [props.previewScreenVisibility])

  useEffect(() => {
    setLoadedModel('PreviewModel')
  }, [nodes])

  if (location === '/home') {
    return (
      <>
        <three.group
          ref={group}
          {...props}
          dispose={null}
          scale={apartmentAnim.scale}
        >
          <mesh
            geometry={nodes['2bhkA'].geometry}
            material={materials['2bhkPreview']}
            rotation={[0, Math.PI / 2, 0]}
            scale={0.01}
          />
        </three.group>
        <HotspotManager
          visibility={state.showHotspots && location === '/home'}
        />
      </>
    )
  } else {
    return null
  }
}

useGLTF.preload('/twoBHKAPreview.glb')
